/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import { Button, Checkbox, Form, Input } from 'antd';
import React from 'react';

function RememberMe() {
  return (
    <Form.Item
      name="remember"
      valuePropName="checked"
      wrapperCol={{
        offset: 8,
        span: 16
      }}
    >
      <Checkbox>Remember me</Checkbox>
    </Form.Item>
  );
}

export default RememberMe;
