/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
import {
  Button,
  Col,
  Row,
  Table,
  BackTop,
  Statistic,
  Alert,
  Space,
  Spin
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TradeFilter from '../../components/TradeFilter/TradeFilter';
import { selectTrade } from '../../features/tradeSlice';
import TradeForm from '../TradePage/TradeForm/TradeForm';

function ExportFilePage() {
  const [tradeLoading, setTradeLoading] = useState(false);
  const [isDataDownLoading, setIsDataDownLoading] = useState(false);
  const { trade } = useSelector(selectTrade);

  return (
    <>
      {isDataDownLoading && (
        <Space
          direction="vertical"
          style={{
            width: '100%'
          }}
        >
          <Spin tip="Please wait...">
            <Alert
              message="Downloading data"
              description="Downloading data...it might takes few minutes..."
              type="info"
            />
          </Spin>
        </Space>
      )}
      {!isDataDownLoading && (
        <div className="site-layout-content">
          <Row
            style={{
              justifyContent: 'left'
            }}
          >
            <TradeFilter
              tradeLoading={tradeLoading}
              setTradeLoading={setTradeLoading}
              setIsDataDownLoading={setIsDataDownLoading}
            />
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <div
                className="grid place-items-center"
                style={{
                  minWidth: '360px',
                  width: '100%',
                  minHeight: '800px',
                  height: '100%',
                  marginTop: '0px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    marginBottom: '10px',
                    fontWeight: '500'
                  }}
                >
                  {trade?.count
                    ? `Total Count : ${trade?.count}`
                    : 'Total Count : 0'}
                </div>
                <TradeForm tradeLoading={tradeLoading} />
              </div>
            </Col>
            <Col
              span={4}
              offset={8}
              style={{
                marginTop: '20px'
              }}
            />
          </Row>
        </div>
      )}
    </>
  );
}

export default ExportFilePage;
