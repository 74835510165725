import React from 'react';
import {
  Button, Col, DatePicker, Row, Select, Statistic, Tooltip,
} from 'antd';
import moment from 'moment/moment';
import { PlayCircleFilled, SyncOutlined } from '@ant-design/icons';

function FetchDataBar(props) {
  const {
    setEnv,
    setStartAt,
    setEndAt,
    startAt,
    endAt,
    count,
    isLive,
    setIsLive,
    fetch,
  } = props;

  return (
    <Row>
      <Col span={20} style={{ marginTop: 10 }}>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
        <label
          style={{
            marginLeft: 10,
          }}
        >
          Environment:
        </label>
        <Select
          defaultValue="dev"
          style={{
            width: 150,
            marginLeft: 5,
          }}
          disabled={isLive}
          onChange={(value) => setEnv(value)}
        >
          <Select.Option key="dev" value="dev">Develop</Select.Option>
          <Select.Option key="pre" value="pre">Pre-Production</Select.Option>
          <Select.Option key="prod" value="prod">Production</Select.Option>
        </Select>
        <DatePicker.RangePicker
          style={{
            marginLeft: 15,
          }}
          onChange={(dates) => {
            if (!dates) return;
            setStartAt(dates[0]);
            setEndAt(dates[1]);
          }}
          defaultValue={[startAt, endAt]}
          showTime={{
            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
          }}
          disabled={isLive}
          format="YYYY-MM-DD HH:mm:ss"
        />
        <Button
          type="primary"
          style={{
            width: 120,
            marginLeft: 15,
          }}
          disabled={isLive}
          onClick={fetch}
        >
          Submit
        </Button>
        <Tooltip title="Fetching data in 5 minutes per 5 seconds" placement="bottom">
          <Button
            style={{ marginLeft: 15 }}
            type="danger"
            shape="round"
            disabled={false}
            onClick={() => setIsLive((pre) => !pre)}
            icon={isLive ? <SyncOutlined spin /> : <PlayCircleFilled />}
          >
            LIVE
          </Button>
        </Tooltip>
      </Col>
      <Col span={4}>
        <Statistic title="Current Total" value={count} />
      </Col>
    </Row>
  );
}

export default FetchDataBar;
