/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
import React, { useState } from 'react';
import {
  BarChartOutlined,
  DesktopOutlined,
  LogoutOutlined,
  PieChartOutlined,
  UserOutlined,
  VideoCameraOutlined,
  CloudDownloadOutlined,
  GiftOutlined,
  FundViewOutlined,
  BlockOutlined,
  ZoomInOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { StopOutlined } from '@ant-design/icons/lib/icons';
import LoginPage from '../pages/LoginPage/LoginPage';
import Whitelist from '../pages/Whitelist';
import Profile from '../pages/LiveStreamPage/Profile/Profile';
import Streams from '../pages/LiveStreamPage/Streams/Streams';
import Dashboard from '../pages/LiveStreamPage/Dashboard/Dashboard';
import RiskDashboard from '../pages/Risk/Dashboard/Dashboard';
import OMSDashboard from '../pages/OMS/Dashboard/Dashboard';
import ExposurePage from '../pages/ExposurePage';
import LogoutPage from '../pages/LogoutPage/LogoutPage';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';
import OpenTradeList from '../pages/TradePage/OpenTradeList/OpenTradeList';
import SettledTradeList from '../pages/TradePage/SettledTradeList/SettledTradeList';
import FundLog from '../pages/TradePage/FundLog/FundLog';
import ExportFilePage from '../pages/ExportFilePage/ExportFilePage';
import { ADMIN, MTBO, MTXX } from '../utils/constants';
import ChangePasswordPage from '../pages/MemberPage/ChangePasswordPage/ChangePasswordPage';
import HLSPlayer from '../pages/LiveStreamPage/HLSPlayer/HLSPlayer';
import Mapping from '../pages/LiveStreamPage/Mapping/Mapping';
import Radar from '../pages/LiveStreamPage/RadarList/Radar';
import BonusPage from '../pages/BonusPage/BonusPage';
import OMSOrderList from '../pages/OMS/OrderList/OrderList';
import OrderList from '../pages/Risk/OrderList/OrderList';
import EventSearch from '../pages/Risk/EventSearch/EventSearch';
import SubsidiaryPerformance from '../pages/ReportPage/SubsidiaryPerformance/SubsidiaryPerformance';
import WinLossByMarketType from '../pages/ReportPage/WinLossByMarketType/WinLossByMarketType';
import WinLossByMarketName from '../pages/ReportPage/WinLossByMarketName/WinLossByMarketName';
import WinLossByEvent from '../pages/ReportPage/WinLossByEvent/WinLossByEvent';
import CustomerWinLoss from '../pages/ReportPage/CustomerWinLoss/CustomerWinLoss';
import CustomerTurnover from '../pages/ReportPage/CustomerTurnover/CustomerTurnover';

const routes = [
  {
    path: '',
    element: <LoginPage />,
    identity: [ADMIN, MTBO, MTXX],
  },
  {
    path: 'member',
    icon: <UserOutlined />,
    label: <Link to="/member">Member</Link>,
    identity: [ADMIN, MTBO, MTXX],
    children: [
      {
        path: 'change-password',
        element: <ChangePasswordPage />,
        label: <Link to="/member/change-password">Change Password</Link>,
        identity: [ADMIN, MTBO, MTXX],
      }
    ]
  },
  {
    path: 'trade',
    icon: <DesktopOutlined />,
    label: 'Trade',
    identity: [ADMIN, MTBO],
    children: [
      {
        path: 'settle-trade-list',
        element: <SettledTradeList />,
        label: <Link to="/trade/settle-trade-list">Settle Trade List</Link>,
        identity: [ADMIN, MTBO],
      },
      {
        path: 'open-trade-list',
        element: <OpenTradeList />,
        label: <Link to="/trade/open-trade-list">Open Trade List</Link>,
        identity: [ADMIN, MTBO],
        default: true
      },
      {
        path: 'fund-log',
        element: <FundLog />,
        label: <Link to="/trade/fund-log">Fund Log</Link>,
        identity: [ADMIN, MTBO],
      }
    ]
  },
  {
    path: 'export-file',
    element: <ExportFilePage />,
    icon: <CloudDownloadOutlined />,
    label: <Link to="/export-file">Export File</Link>,
    identity: [ADMIN, MTBO],
  },

  {
    path: 'report',
    icon: <ZoomInOutlined />,
    label: 'Report',
    identity: [ADMIN, MTBO, MTXX],
    children: [
      {
        path: 'customer-turnover',
        element: <CustomerTurnover />,
        label: <Link to="/report/customer-turnover">Customer Turnover</Link>,
        identity: [ADMIN, MTBO, MTXX],
        default: true
      },
      {
        path: 'customer-win-loss',
        element: <CustomerWinLoss />,
        label: <Link to="/report/customer-win-loss">Customer Win Loss</Link>,
        identity: [ADMIN, MTBO, MTXX],
      },
      {
        path: 'win-loss-by-event',
        element: <WinLossByEvent />,
        label: <Link to="/report/win-loss-by-event">Win Loss By Event</Link>,
        identity: [ADMIN, MTBO, MTXX],
      },
      {
        path: 'win-loss-by-market-name',
        element: <WinLossByMarketName />,
        label: (
          <Link to="/report/win-loss-by-market-name">
            Win Loss By Market Name
          </Link>
        ),
        identity: [ADMIN, MTBO, MTXX],
      },
      {
        path: 'win-loss-by-market-type',
        element: <WinLossByMarketType />,
        label: (
          <Link to="/report/win-loss-by-market-type">
            Win Loss By Market Type
          </Link>
        ),
        identity: [ADMIN, MTBO, MTXX],
      },
      {
        path: 'subsidiary-performance',
        element: <SubsidiaryPerformance />,
        label: <Link to="/report/subsidiary-performance">Subsidiary Performance</Link>,
        identity: [ADMIN, MTBO, MTXX],
      }
    ]
  },

  {
    path: 'exposure',
    element: <ExposurePage />,
    icon: <FundViewOutlined />,
    label: <Link to="/exposure">Exposure</Link>,
    identity: [ADMIN, MTBO],
  },
  {
    path: 'bonus',
    element: <BonusPage />,
    icon: <GiftOutlined />,
    label: <Link to="/bonus">Bonus</Link>,
    identity: [ADMIN, MTBO],
  },
  {
    path: 'livestream',
    label: 'Live Stream',
    identity: ADMIN,
    icon: <VideoCameraOutlined />,
    children: [
      {
        path: 'profile',
        element: <Profile />,
        label: <Link to="/livestream/profile">Profile</Link>,
        identity: ADMIN
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
        label: <Link to="/livestream/dashboard">Dashboard</Link>,
        identity: ADMIN
      },
      {
        path: 'streams',
        element: <Streams />,
        label: <Link to="/livestream/streams">Streams</Link>,
        identity: ADMIN
      },
      {
        path: 'mapping',
        element: <Mapping />,
        label: <Link to="/livestream/mapping">Mapping</Link>,
        identity: ADMIN
      },
      {
        path: 'radar',
        element: <Radar />,
        label: <Link to="/livestream/radar">Radar List</Link>,
        identity: ADMIN
      },
      // TODO 待優化
      {
        path: 'hlsPlayer',
        element: <HLSPlayer />,
        label: <Link to="/livestream/hlsPlayer">HLS Player</Link>,
        identity: ADMIN
      }
    ]
  },
  {
    path: 'oms',
    label: 'OMS',
    identity: ADMIN,
    icon: <BlockOutlined />,
    children: [
      {
        path: 'dashboard',
        element: <OMSDashboard />,
        label: <Link to="/oms/dashboard">Dashboard</Link>,
        identity: ADMIN
      },
      {
        path: 'order-list',
        element: <OMSOrderList />,
        label: <Link to="/oms/order-list">Order List</Link>,
        identity: ADMIN
      }
    ]
  },
  {
    path: 'risk',
    label: 'Risk',
    identity: ADMIN,
    icon: <StopOutlined />,
    children: [
      {
        path: 'dashboard',
        element: <RiskDashboard />,
        label: <Link to="/risk/dashboard">Dashboard</Link>,
        identity: ADMIN
      },
      {
        path: 'event-search',
        element: <EventSearch />,
        label: <Link to="/risk/event-search">Event Search</Link>,
        identity: ADMIN
      },
      {
        path: 'order-list',
        element: <OrderList />,
        label: <Link to="/risk/order-list">Order List</Link>,
        identity: ADMIN
      }
    ]
  },
  {
    path: 'whitelist',
    element: <Whitelist />,
    icon: <PieChartOutlined />,
    label: <Link to="/whitelist">Whitelist</Link>,
    identity: ADMIN
  },
  {
    path: 'logout',
    element: <LogoutPage />,
    icon: <LogoutOutlined />,
    label: <Link to="/logout">Log out</Link>,
    identity: [ADMIN, MTBO, MTXX],
  },
  {
    path: '*',
    element: <NotFoundPage />,
    identity: [ADMIN, MTBO, MTXX],
  }
];

export default routes;
