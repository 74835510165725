/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable comma-dangle */
import React from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import PlayerAndValidTradeForm from './PlayerAndValidTradeForm/PlayerAndValidTradeForm';
import { selectExposure } from '../../../features/exposureSlice';

const columns = [
  {
    title: 'Market name',
    dataIndex: 'marketName',
    width: '13vw'
  },
  {
    title: 'Player',
    dataIndex: 'player',
    width: '13vw'
  },
  {
    title: '1',
    dataIndex: 'one',
    width: '13vw'
  },
  {
    title: 'X',
    dataIndex: 'X',
    width: '13vw'
  },
  {
    title: '2',
    dataIndex: 'two',
    width: '13vw'
  },
  {
    title: 'Valid trade',
    dataIndex: 'validTrade',
    width: '13vw'
  }
];

const data = [
  {
    key: '1',
    player: '',
    marketName: 'Full Time Result',
    one: '+ 180,000,000',
    X: '+ 180,000,000',
    two: '+ 180,000,000'
  }
];

const onChange = (pagination, filters, sorter, extra) => {
  console.log('params', pagination, filters, sorter, extra);
};

function ExposureForm() {
  const exposure = useSelector(selectExposure);

  return (
    <Table
      columns={columns}
      dataSource={data}
      onChange={onChange}
      pagination={{ pageSize: 100 }}
      expandRowByClick
      expandable={{
        expandedRowRender: (record) => <PlayerAndValidTradeForm />
      }}
    />
  );
}

export default ExposureForm;
