/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable comma-dangle */
import React from 'react';
import { Table } from 'antd';

const columns = [
  {
    title: 'Market name',
    dataIndex: 'marketName',
    width: '13vw'
    // fixed: 'left',
    // width: 380
  },
  {
    title: 'Player',
    dataIndex: 'player',
    width: '12.8vw'
  },
  {
    title: '1',
    dataIndex: 'one',
    width: '12.8vw'
  },
  {
    title: 'X',
    dataIndex: 'X',
    width: '12.8vw'
  },
  {
    title: '2',
    dataIndex: 'two',
    width: '12.8vw'
  },
  {
    title: 'Valid trade',
    dataIndex: 'validTrade',
    width: '12.8vw'
    // fixed: 'right',
    // width: 290
  }
];

const data = [
  {
    key: '1',
    marketName: '',
    player: 'John Browny',
    one: '+ 180,000,000',
    X: '+ 180,000,000',
    two: '+ 180,000,000',
    validTrade: 6800
  }
];

const onChange = (pagination, filters, sorter, extra) => {
  console.log('params', pagination, filters, sorter, extra);
};

function PlayerAndValidTradeForm() {
  return (
    <Table
      showHeader={false}
      columns={columns}
      dataSource={data}
      onChange={onChange}
      pagination={{ pageSize: 100 }}
      pagination={false}
      expandRowByClick
    />
  );
}

export default PlayerAndValidTradeForm;
