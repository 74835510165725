/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable comma-dangle */

const useGetOrder = (trade) => {
  const getOrder = (perOrder, tradeID) => {
    const result = {
      tradeID,
      key: perOrder?.place_order_id,
      placeOrderID: perOrder?.place_order_id,
      productID: perOrder?.product_id,
      selection: perOrder?.product_name?.en,
      layBack: perOrder?.layback,
      line: perOrder?.line,
      placePrice: perOrder?.place_order_price,
      placetime: perOrder?.place_order_dt,
      unmatchedSize: perOrder?.unmatched_order_quantity,
      avgMatchedPrice: perOrder?.matched_order_price,
      matchedSize: perOrder?.matched_order_quantity,
      matchedTime: perOrder?.matched_order_dt,
      cancelSize: perOrder?.cancel_order_quantity,
      voidReason: perOrder?.settle_remark,
      outcome: perOrder?.outcome_name?.en
    };
    return result;
  };

  const getOrders = (allOrders, tradeID) => {
    const result = [];
    allOrders?.forEach((perOrder) => result.push(getOrder(perOrder, tradeID)));
    return result;
  };

  const columns = [
    {
      title: 'TradeID',
      dataIndex: 'tradeID',
      width: 600
    },
    {
      title: 'Place order id',
      dataIndex: 'placeOrderID',
      width: 100
    },
    {
      title: 'Product id',
      dataIndex: 'productID',
      width: 100
    },
    {
      title: 'Selection',
      dataIndex: 'selection',
      width: 100
    },
    {
      title: 'Lay Back',
      dataIndex: 'layBack',
      width: 100
    },
    {
      title: 'Line',
      dataIndex: 'line',
      width: 100
    },
    {
      title: 'Place price',
      dataIndex: 'placePrice',
      width: 100
    },
    {
      title: 'Place time (+0)',
      dataIndex: 'placetime',
      width: 100
    },
    {
      title: 'Unmatched size',
      dataIndex: 'unmatchedSize',
      width: 100
    },
    {
      title: 'Avg matched price',
      dataIndex: 'avgMatchedPrice',
      width: 100
    },
    {
      title: 'Matched size',
      dataIndex: 'matchedSize',
      width: 100
    },
    {
      title: 'Matched time (+0)',
      dataIndex: 'matchedTime',
      width: 100
    },
    {
      title: 'Cancel size',
      dataIndex: 'cancelSize',
      width: 100
    },
    {
      title: 'Void reason',
      dataIndex: 'voidReason',
      width: 100
    },
    {
      title: 'Outcome',
      dataIndex: 'outcome',
      width: 100
    }
  ];

  const tradeID = trade?.tradeID;
  const orders = trade?.tradeDetail;
  const data = getOrders(orders, tradeID);

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return {
    columns,
    data,
    onChange
  };
};

export default useGetOrder;
