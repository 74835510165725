/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable comma-dangle */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useState } from 'react';

const useGetBonus = () => {
  const initialState = [
    {
      key: 1,
      bonusName: 'John Brown',
      marketType: 'Match odds',
      league: 'world women cup 2022',
      multiple: '123',
      amount: 2000,
      startDate: '2022-09-02',
      EndDate: '2022-09-03'
    }
  ];
  const [dataSource, setDataSource] = useState(initialState);

  const onDeleteBonus = (record) => {
    Modal.confirm({
      title: 'Are you sure, you want to delete this bonus record?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      canceltext: 'No',

      onOk() {
        console.log('OK');
        setDataSource((pre) => pre.filter((bonus) => bonus.key !== record.key));
      },

      onCancel() {
        console.log('Cancel');
      }
    });
  };

  const columns = [
    {
      title: 'Bonus name',
      dataIndex: 'bonusName',
      key: 'bonusName'
    },
    {
      title: 'Market type',
      dataIndex: 'marketType',
      key: 'marketType'
    },
    {
      title: 'League',
      dataIndex: 'league',
      key: 'league'
    },
    {
      title: 'Multiple',
      dataIndex: 'multiple',
      key: 'multiple'
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount'
    },
    {
      title: 'Start date',
      dataIndex: 'startDate',
      key: 'startDate'
    },
    {
      title: 'End date',
      dataIndex: 'endDate',
      key: 'endDate'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record) => (
        <a onClick={onDeleteBonus.bind(null, record)}>Delete</a>
      )
    }
  ];

  const onAddBonus = () => {
    const randomNumber = parseInt(Math.random() * 1000);
    const newBonus = {
      key: randomNumber,
      bonusName: 'John Brown',
      marketType: 'Match odds',
      league: 'world women cup 2022',
      multiple: '123',
      amount: 2000,
      startDate: '2022-09-02',
      endDate: '2022-09-03'
    };
    setDataSource((pre) => [...pre, newBonus]);
  };

  return {
    dataSource,
    setDataSource,
    columns,
    onAddBonus
  };
};

export default useGetBonus;
