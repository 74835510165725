/* eslint-disable comma-dangle */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {},
    accessToken: {},
    apiKey: {},
    aud: ''
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.apiKey = action.payload.apiKey;
      state.aud = action.payload.aud;
    }
  }
});

// actions
export const { setUserInfo } = userSlice.actions;

// 原本要在 component 中拿出的 state, 先在這邊拿出
export const selectUserInfo = (state) => state.user;

export default userSlice.reducer;
