/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledTradeForm = styled.div`
  .ant-table-tbody {
    tr:last-child {
      position: sticky;
      z-index: 10;
      top: -1px;
      bottom: 0px;
      background-color: white;
    }
  }
`;
