/* eslint-disable */
import React from 'react';
import {Badge, Button, Card, Dropdown, Input, Menu, Modal, Select, Space, Table, Tag} from 'antd';
import useEventSearch from "./useEventSearch";
import moment from "moment/moment";

function EventSearch() {
  const {
      loading,
      env,
      setEnv,
      eventID,
      setEventID,
      data,
      modalData,
      setModalData,
      isModalOpen,
      setIsModalOpen,
      fetch,
      closeMarkets,
      closeAllMarkets,
      closeAllProducts,
      selectedMarketRowKeys,
      setSelectedMarketRowKeys,
  } = useEventSearch()

  const showModal = (data) => {
    setModalData(data)
    setIsModalOpen(true);
  };
  const handleOk = async () => {
      modalData.market_ids
          ? await closeMarkets()
          : modalData.market_id
          ? await closeAllProducts()
          : await closeAllMarkets()
      setIsModalOpen(false)
      fetch();
  }
  const expandedProductRowRender = (record) => {
        const columns = [
            {
                title: 'Product ID',
                dataIndex: 'product_id',
                key: 'product_id',
            },
            {
                title: 'Product',
                dataIndex: 'product_name',
                key: 'product_name',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (_, { status, product_id}) => (
                    <Tag color='green' key={product_id}>
                        {status.toUpperCase()}
                    </Tag>
                ),
            },
        ];
        let productData = []
        record.product.forEach(item => {
            productData.push({
                product_id: item.product_id,
                product_name: item.product_name,
                status: item.status,
            });
        })
        return <Table
            columns={columns}
            dataSource={productData}
            rowKey="product_id"
            pagination={false}
        />;
    };
  const expandedRowRender = (record) => {
        const columns = [
            {
                title: 'Market ID',
                dataIndex: 'market_id',
                key: 'market_id',
            },
            {
                title: 'Market Name',
                dataIndex: 'market_name',
                key: 'market_name',
            },
            {
                title: 'Market Label',
                dataIndex: 'market_label',
                key: 'market_name',
                render: (_, { market_label }) => (
                            market_label.map(tag => (
                                <Tag color={tag.length > 5 ? 'geekblue' : 'green'} key={tag}>
                                    {tag.toUpperCase()}
                                </Tag>
                            ))
                ),
            },
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                    <Space size="middle">
                        <a onClick={()=> showModal(record)}>Close Market</a>
                    </Space>
                ),
            },
        ];

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                setSelectedMarketRowKeys(selectedRowKeys)
            },
        };

        const epData = [];
        record.c_product.forEach((event) => {
            epData.push({
                market_id: event.market_id,
                market_name: event.market_name,
                market_label: event.market_label,
                product: event.product,
            });
        });

        return <Table
            columns={columns}
            dataSource={epData}
            rowKey="market_id"
            pagination={false}
            rowSelection={rowSelection}
            expandable={{
                expandedRowRender: (record) => expandedProductRowRender(record),
            }}
        />;
    };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'event_id',
      key: 'event_id',
    },
      {
          title: 'League',
          dataIndex: 'season_name',
          key: 'season_name',
      },
      {
          title: 'Country',
          dataIndex: 'country',
          key: 'country',
      },

    {
        title: 'PreMatch / InPlay',
        dataIndex: 'status',
        key: 'status',
        render: (_, {status, id}) => <Tag color={status === 'started' ? 'green' : 'yellow'} key={id}>
            {status.toUpperCase()}
        </Tag>

    },
    {
        title: 'Event Type',
        dataIndex: 'event_type',
        key: 'event_type',
    },
      {
          title: 'Team A',
          dataIndex: 'team_a',
          key: 'team_a',
          render: (_, {team_a}) => team_a.name
      },
      {
          title: 'Team B',
          dataIndex: 'team_b',
          key: 'team_b',
          render: (_, {team_b}) => team_b.name
      },
    {
        title: 'Market Count',
        dataIndex: 'market_count',
        key: 'market_count',
    },
      {
          title: 'Start Date',
          dataIndex: 'start_date',
          key: 'start_date',
          render: (_, record) => (
              <div>
                  {moment(record.start_date).format('YYYY-MM-DD HH:mm:ss')}
              </div>
          ),
      },
      {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
              <Space size="middle">
                  <a onClick={()=> showModal(record)}>Close Event</a>
              </Space>
          ),
      },
  ];

  return (
    <Card>
      <div style={{
        marginBottom: 20,
        marginTop: 10,
      }}>
        <label
            style={{
              marginLeft: 10,
            }}
        >Environment:</label>
        <Select
            defaultValue="dev"
            style={{
              width: 100,
              marginLeft: 5,
            }}
            onChange={(value) => setEnv(value)}
        >
          <Select.Option key="dev" value="dev" >Develop</Select.Option>
          <Select.Option key="prod" value="prod">Production</Select.Option>
        </Select>
          <label
              style={{
                  marginLeft: 10,
              }}
          >Event ID:</label>
          <Input
              style={{
                  width: 200,
                  marginLeft: 5,
              }}
              placeholder="Enter Event ID..."
              onChange={(e) => setEventID(e.target.value)}
          ></Input>
        <Button
            type="primary"
            style={{
              width: 120,
              marginLeft: 30,
            }}
            onClick={() => {
              fetch()
            }}
        >
          Submit
        </Button>
          {
              selectedMarketRowKeys.length !== 0 &&
              <Button
                  type="danger"
                  style={{
                      width: 120,
                      marginLeft: 30,
                  }}
                  onClick={() => {
                      showModal({market_ids: selectedMarketRowKeys})
                  }}
              >
                  Close Markets
              </Button>
          }
      </div>
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          defaultPageSize: 15,
        }}
        expandable={{
            expandedRowRender: record => expandedRowRender(record),
            defaultExpandedRowKeys: ['0'],
        }}
      />
        <Modal title="Close Items" open={isModalOpen} onOk={()=>handleOk()} onCancel={()=> setIsModalOpen(false)}>
            <p>Current Env: <Tag color="geekblue">{env}</Tag></p>
            {
                modalData.market_ids
                    ? <p>You are Closing the Selected Markets on Market IDs : {
                        modalData.market_ids.map(market_id => (<Tag color="red">{market_id}</Tag>))
                    }</p>
                : modalData.market_id
                    ? <p>You are Closing the Products on Market ID : <Tag color="red">{modalData?.market_id}</Tag></p>
                    : <p>You are Closing the Markets on Event ID : <Tag color="red">{modalData?.event_id}</Tag></p>
            }
        </Modal>
    </Card>
  );
}

export default EventSearch;
