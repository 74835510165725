/* eslint-disable object-curly-newline */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable comma-dangle */
import React from 'react';
import { GoogleButton } from 'react-google-button';
import useOnAuthStateChanged from '../../../hooks/useOnAuthStateChanged';
import { authGoogle } from '../../../utils/firebase-config-google';
import useGoogleLogin from './useGoogleLogin';

function GoogleLogin() {
  const { signInWithGoogle } = useGoogleLogin();
  useOnAuthStateChanged(authGoogle);

  return <GoogleButton onClick={signInWithGoogle} />;
}

export default GoogleLogin;
