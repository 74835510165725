/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { getRadarList } from '../../../Api/streamAPI';

const filterFormat = (item) => ({
  text: item,
  value: item,
});
function useRadar() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sportTypeFilter, setSportTypeFilter] = useState([]);
  const [oppAFilter, setOppAFilter] = useState([]);
  const [oppBFilter, setOppBFilter] = useState([]);
  const [ligaFilter, setLigaFilter] = useState([]);
  const [ligaIdFilter, setLigaIdFilter] = useState([]);
  const [streamNameFilter, setStreamNameFilter] = useState([]);

  const fetch = async () => {
    setLoading(true);
    const radar = await getRadarList('all');
    const stFilter = [];
    const opaFilter = [];
    const opbFilter = [];
    const lgFilter = [];
    const lgIdFilter = [];
    const streamNFilter = [];
    radar.data.forEach((item) => {
      !stFilter.includes(item.SportEn) && stFilter.push(item.SportEn);
      !opaFilter.includes(item.Opp1En) && opaFilter.push(item.Opp1En);
      !opbFilter.includes(item.Opp2En) && opbFilter.push(item.Opp2En);
      !lgFilter.includes(item.LigaEn) && lgFilter.push(item.LigaEn);
      !lgIdFilter.includes(item.LeagueID) && lgIdFilter.push(item.LeagueID);
      !streamNFilter.includes(item.StreamName) && streamNFilter.push(item.StreamName);
    });
    setSportTypeFilter(stFilter.map(filterFormat));
    setOppAFilter(opaFilter.map(filterFormat));
    setOppBFilter(opbFilter.map(filterFormat));
    setLigaFilter(lgFilter.map(filterFormat));
    setLigaIdFilter(lgIdFilter.map(filterFormat));
    setStreamNameFilter(streamNFilter.map(filterFormat));
    setLoading(false);
    setData(radar.data);
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    loading,
    oppAFilter,
    oppBFilter,
    data,
    sportTypeFilter,
    ligaFilter,
    ligaIdFilter,
    streamNameFilter,
  };
}

export default useRadar;
