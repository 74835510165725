/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { isIP } from 'is-ip';
import policyInfos from '../../utils/sercure-policy-config';

import {sleep} from '../../utils/helper';
import useGapi from './useGapi';

function useWhitelist() {
  // eslint-disable-next-line no-bitwise
  const [inputStatus, setInputStatus] = useState('error' | 'warning' | '');
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);
  const [whitelist, setWhitelist] = useState([]);
  const [inputIP, setInputIP] = useState('');
  const [targetKeys, setTargetKeys] = useState([]);
  const [submitUpdate, setSubmitUpdate] = useState(false);
  const [maxPriority, setMaxPriority] = useState(10000);

  const limitPriority = 10000
  const mainPolicyInfo = policyInfos[0];
  const perRuleLimit = 50;
  const defaultPriority = mainPolicyInfo.priority;
  const {
    gapiLoadRestApi,
    gapiAuthInit,
    gapiUpdateSecurityPolicyRule,
    gapiInsertSecurityPolicyRule,
    gapiGetSecurityPolicy,
    gapiAuthSignIn,
    gapiDeleteSecurityPolicyRule,
  } = useGapi();

  const authenticate = async () => {
    try {
      await gapiAuthInit();
      await sleep(1000);
      await gapiAuthSignIn();
      await gapiLoadRestApi();
      message.success('Oauth2 Sign-in successful');
    } catch (err) {
      message.error(`Error signing in or Error loading GAPI client for API: ${err.error}`);
    }
  };

  const getWhitelistArrByExpression = (expression) => {
    const rule = expression.match(/"(\S*)"\.contains/);
    if (!rule[1]) {
      message.error('Invalid Whitelist format from Rule Expression');
      return [];
    }
    return rule[1].split(',');
  };

  const getWhitelist = async (policyInfo) => {
    try {
      let whitelistArr = [];
      const res = await gapiGetSecurityPolicy(policyInfo);
      res.result.rules.forEach((item) => {
        if (item.action !== 'allow' || item.priority < limitPriority) return;
        if (item.priority > maxPriority) setMaxPriority(item.priority);
        whitelistArr = [
          ...whitelistArr,
          ...getWhitelistArrByExpression(item.match.expr.expression),
        ];
      });
      setWhitelist(whitelistArr);
    } catch (err) {
      message.error(`Execute error ${err}`);
    }
  };

  const addWhiteIP = () => {
    if (!inputIP) {
      setInputStatus('error');
      setErrorText('The IP input couldn\'t be empty');
      return;
    }

    if (!isIP(inputIP.split('/')[0])) {
      setInputStatus('error');
      setErrorText('Invalid IP Address');
      return;
    }

    const newArr = [...new Set([...whitelist, inputIP])];
    if (newArr.length === whitelist.length) {
      setInputStatus('error');
      setErrorText('The IP has existed');
      return;
    }

    setInputStatus('');
    setWhitelist((prev) => [...prev, inputIP]);
    setInputIP('');
  };

  const updateWhitelist = () => setSubmitUpdate(true);

  const getWhiteListGroup = (whitelist, limit) => {
    const whitelistGroup = [];
    const uniWhitelist = [...new Set([...whitelist])];
    for (let i = 0; i < uniWhitelist.length; i += limit) {
      whitelistGroup.push(uniWhitelist.splice(i, i + limit));
    }
    if(uniWhitelist.length > 0) whitelistGroup.push(uniWhitelist);
    return whitelistGroup;
  };

  useEffect(() => {
    if (!submitUpdate) return;
    setLoading(true);
    setWhitelist((prev) => prev.filter((item, index) => !targetKeys.includes(index.toString())));
  }, [submitUpdate]);

  useEffect(async () => {
    if (!submitUpdate) return;
    setSubmitUpdate(false);
    setTargetKeys([]);

    try {
      const whitelistGroup = getWhiteListGroup([...whitelist], perRuleLimit);

      // remove Rule
      let predictPriority = defaultPriority + (whitelistGroup.length - 1)
      if ( predictPriority < maxPriority) {
        for (let priority = predictPriority + 1; priority <= maxPriority; priority++) {
          for (const policyInfo of policyInfos) {
            await gapiDeleteSecurityPolicyRule({
              project: policyInfo.project,
              securityPolicy: policyInfo.securityPolicy,
              priority: priority
            })
          }
        }
        setMaxPriority(predictPriority)
      }

      for (let i = 0; i < whitelistGroup.length; i++) {
        const expression = ['"', [...whitelistGroup[i]].join(','), '".contains(origin.ip)'].join('');
        for (const policyInfo of policyInfos) {
          const policyUpdateInfo = {
            project: policyInfo.project,
            securityPolicy: policyInfo.securityPolicy,
            priority: parseInt(policyInfo.priority, 10) + i,
            resource: {
              action: 'allow',
              priority: parseInt(policyInfo.priority, 10) + i,
              match: { expr: { expression } },
            },
          };

          // updateOrCreate Rule
          if ((policyUpdateInfo.priority) > maxPriority) {
            delete policyUpdateInfo.priority;
            await gapiInsertSecurityPolicyRule(policyUpdateInfo);
            continue;
          }
          await gapiUpdateSecurityPolicyRule(policyUpdateInfo);
        }
      }
      await sleep(2000);
      await getWhitelist(mainPolicyInfo);
      await setLoading(() => false);
      message.success('Whitelist Updated');
    } catch (err) {
      setLoading(() => false);
      message.error(`Execute error ${err}`);
    }
  }, [whitelist]);

  useEffect(async () => {
    try {
      await authenticate();
      await getWhitelist(mainPolicyInfo);
    } catch (err) {
      message.error(`Init error: ${err.error}, please try it again later`);
    }
  }, []);

  return {
    updateWhitelist,
    addWhiteIP,
    getWhitelist,
    inputStatus,
    errorText,
    loading,
    inputIP,
    setInputIP,
    targetKeys,
    setTargetKeys,
    whitelist,
    setWhitelist,
  };
}

export default useWhitelist;
