function formatDate(date) {
  const d = new Date(date);
  const year = d.getFullYear();
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  let hour = d.getHours();
  let minute = d.getMinutes();
  let second = d.getSeconds();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  if (hour < 10) {
    hour = `0${hour}`;
  }

  if (minute < 10) {
    minute = `0${minute}`;
  }

  if (second < 10) {
    second = `0${second}`;
  }

  const result = `${[year, month, day].join('-')}T${hour}:${minute}:${second}Z`;

  return result;
}

export default formatDate;
