import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { round } from 'lodash';
import { getCurrencyRatesApi } from '../Api/omsAPI';

const MainCurrency = 'EUR';
const MainCurrencySyntax = '€';
const MtsRateList = {
  VND: 5000000,
  INR: 10000,
};
function useCurrency() {
  const [currencyRates, setCurrencyRates] = useState({});
  const fetchCurrencyRates = async (currency) => {
    try {
      const { data: { rates } } = await getCurrencyRatesApi(currency);
      setCurrencyRates(rates);
    } catch (e) {
      // 如果發生錯誤則返回預設匯率
      message.error('Get Currency Rates Error, instead of using default rates ');
      const defaultRates = {
        USD: 1,
        THB: 37.08932,
        INR: 84.436587,
        PHP: 59.222543,
        EUR: 1,
        VND: 25651.753857,
        CNY: 7.372291,
      };
      setCurrencyRates(defaultRates);
    }
  };
  const formatCurrency = (amount) => {
    const num = round(amount, 2);
    return num === 0 ? `< ${MainCurrencySyntax} 0.01` : `${MainCurrencySyntax} ${num}`;
  };
  const getMtsRate = (currency) => (MtsRateList[currency] ? MtsRateList[currency] : 5000);

  useEffect(() => {
    fetchCurrencyRates(MainCurrency);
  }, []);
  return {
    currencyRates,
    MainCurrency,
    formatCurrency,
    getMtsRate,
  };
}

export default useCurrency;
