import { getAuth, signOut } from 'firebase/auth';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { selectUserInfo } from '../../features/userSlice';
import { authGoogle } from '../../utils/firebase-config-google';
import { authMtbo } from '../../utils/firebase-config-mtbo';

const useLogout = () => {
  const navigate = useNavigate();

  const logout = () => {
    signOut(authGoogle)
      .then(() => {
        // Sign-out successful
        // clear sessionStorage
        sessionStorage.clear();
        // redirect to login page
        navigate('/');
        message.success('logout successfully!');
      })
      .catch((error) => {
        // // An error happened.
        message.error('Google logout error:', error);
      });

    signOut(authMtbo)
      .then(() => {
        // Sign-out successful
        // clear sessionStorage
        sessionStorage.clear();
        // redirect to login page
        navigate('/');
        message.success('logout successfully!');
      })
      .catch((error) => {
        // // An error happened.
        message.error('MTBO logout failed:', error);
      });
  };

  return { logout };
};

export default useLogout;
