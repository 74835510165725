/* eslint-disable no-restricted-exports */
/* eslint-disable import/no-relative-packages */
import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store from './app/store';

import App from './App';
import './index.scss';

import { interceptor } from './Api/tradeAPI';

interceptor(store);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
