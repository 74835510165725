/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react';
import { Card, Table } from 'antd';
import ReactHlsPlayer from "react-hls-player";
import useProfile from './useProfile';
import { HddOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../features/userSlice';
import axios from "axios";
// import useToken from '../../../hooks/useToken';

const columns = [
  {
    dataIndex: 'name',
    key: 'name',
    width: 200
  },
  {
    dataIndex: 'value',
    key: 'value'
  }
];

function Profile() {
  const { data, loading } = useProfile();

  return (
    <Card
      title={
        <Fragment>
          <HddOutlined />
          <span style={{ paddingLeft: '12px', fontSize: '16px' }}>
            Server Info
          </span>
        </Fragment>
      }
    >
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={false}
        showHeader={false}
      />
    </Card>
  );
}

export default Profile;
