/* eslint-disable no-alert */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
import { message } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updatePassword } from 'firebase/auth';
import { authMtbo } from '../../../utils/firebase-config-mtbo';

const useChangePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleNewPasswordClick = () => {
    updatePassword(authMtbo.currentUser, newPassword)
      .then(() => {
        message.success('password changed successfully! please login again');
        authMtbo
          .signOut()
          .then(() => {
            navigate('/');
          })
          .catch((error) => {
            message.error(error.message);
          });
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  return {
    onFinish,
    onFinishFailed,
    setNewPassword,
    handleNewPasswordClick
  };
};

export default useChangePassword;
