import { useLocation, useParams } from 'react-router-dom';

function useRouter() {
  const params = useParams();
  const location = useLocation();
  const { pathname } = location;
  const pathArr = pathname.split('/');

  return {
    params,
    location,
    pathname,
    pathArr,
  };
}

export default useRouter;
