/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
import { Col, Row, Table } from 'antd';
import React from 'react';
import BonusFilter from '../../components/BonusFilter/BonusFilter';
import BonusForm from './BonusForm/BonusForm';

function BonusPage() {
  return (
    <div className="site-layout-content">
      <Row
        style={{
          justifyContent: 'left'
        }}
      >
        <BonusFilter />
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <div
            className="grid place-items-center"
            style={{
              minWidth: '360px',
              width: '100%',
              minHeight: '800px',
              height: '100%',
              marginTop: '40px'
            }}
          >
            <BonusForm />
          </div>
        </Col>
        <Col
          span={4}
          offset={8}
          style={{
            marginTop: '20px'
          }}
        />
      </Row>
    </div>
  );
}

export default BonusPage;
