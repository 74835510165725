/* eslint-disable  */
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../features/userSlice';

import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import {
  ADMIN,
  AUD_FIRST_PARAM_AD,
  AUD_FIRST_PARAM_MT,
  AUD_FIRST_PARAM_SMT,
  AUD_THIRD_PARAM_MTXX,
  GOOGLE_AUTH_HD_PARAM,
  MTBO,
  MTXX,
} from '../utils/constants';

const useIdentity = () => {
  const userInfo = useSelector(selectUserInfo);
  const [identity, setIdentity] = useState('');
  const [merchantId, setMerchantId] = useState('');

  useEffect(() => {
    if (userInfo.user) setIdentity(userInfo.user);
    else setIdentity('');
  }, [userInfo.user]);
  
  useEffect(() => {
    if (userInfo.aud) setMerchantId(userInfo.aud);
    else setMerchantId('');
  }, [userInfo.aud]);

  const getIdentity = (token) => {
    // 將 token 解碼
    // 獲取解碼後的 aud 來判斷身份
    const decodeToken = jwt_decode(token);
    if (
      // 管理者
      decodeToken?.hd === GOOGLE_AUTH_HD_PARAM ||
      decodeToken?.aud.split('-')[0] === AUD_FIRST_PARAM_AD
    ) {
      return ADMIN;
    }

    if (
      // 商戶
      (decodeToken?.aud.split('-')[0] === AUD_FIRST_PARAM_MT ||
        decodeToken?.aud.split('-')[0] === AUD_FIRST_PARAM_SMT) &&
      decodeToken?.aud.split('-')[2] !== AUD_THIRD_PARAM_MTXX
    ) {
      return MTBO;
    }

    if (
      // 總代
      decodeToken?.aud.split('-')[2] === AUD_THIRD_PARAM_MTXX
    ) {
      return MTXX;
    }
    return {};
  };

  return {
    getIdentity,
    identity,
    merchantId
  };
};

export default useIdentity;
