/* eslint-disable operator-linebreak */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from '../../../ routes';
import { MTBO } from '../../../utils/constants';

function MtboRoutes() {
  return (
    <Routes>
      {routes.map((route) => (
        <Route
          key={route.identity.includes(MTBO) && route.path}
          path={route.identity.includes(MTBO) && route.path}
          element={route.identity.includes(MTBO) && route?.element}
        >
          {route.identity.includes(MTBO) &&
            route.children &&
            route.children.map((child) => (
              <Route
                key={child.path}
                path={child.path}
                element={child.element}
              />
            ))}
        </Route>
      ))}
    </Routes>
  );
}

export default MtboRoutes;
