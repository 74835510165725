/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from 'react';
import {
  Table,
  Col,
  Row,
  Statistic,
} from 'antd';
import { round, each, groupBy } from 'lodash';
import { Progress } from '@ant-design/charts';

function MerchantRankTable({
  data, count, currencyRates, MainCurrency, getMtsRate,
}) {
  const [columnsData, setColumnsData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [completedPercent, setCompletedPercent] = useState(0);

  useEffect(() => {
    const dd = [];
    let completedCount = 0;
    each(groupBy(data, 'merchantCode'), (item, key) => {
      completedCount += item.reduce((pre, curr) => (curr.status === 'completed' ? pre + 1 : pre), 0);
      const amount = item.reduce((pre, curr) => {
        const currency = curr.ticket?.Sender?.Currency;
        const currencyRate = currencyRates[currency];
        // Ignore not supported currency
        if (!currency || !currencyRate || curr.status !== 'completed') return pre;
        // eslint-disable-next-line max-len,no-shadow
        return pre + curr.ckexOrder.payload.reduce((pre1, curr1) => pre1 + parseFloat(curr1.quantity), 0) / currencyRate;
      }, 0);
      dd.push({
        merchantCode: key,
        orderCount: item.length,
        percent: 10,
        amount: round(amount, 2),
      });
    });
    dd.sort((a, b) => b.amount - a.amount);
    setColumnsData(dd);
    setTotalAmount(dd.reduce((pre, curr) => pre + curr.amount, 0));
    setCompletedPercent(round(completedCount * 100 / count, 2));
  }, [currencyRates]);

  const columns = [
    {
      title: 'Merchant Code',
      dataIndex: 'merchantCode',
      key: 'merchantCode',
    },
    {
      title: 'Order Count',
      dataIndex: 'orderCount',
      key: 'orderCount',
      sorter: (a, b) => b.orderCount - a.orderCount,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, { amount }) => `€ ${amount}`,
      sorter: (a, b) => b.amount - a.amount,
    },
    {
      title: 'Percent',
      dataIndex: 'percent',
      key: 'percent',
      render: (item, record, index) => {
        // eslint-disable-next-line no-mixed-operators
        const percent = round(record.amount / totalAmount * 100, 2);
        const config = {
          height: 20,
          width: 120,
          autoFit: true,
          percent: percent / 100,
          color: ['#5B8FF9', '#E8EDF3'],
        };
        return (
          <Row>
            <Col span={18}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Progress {...config} />
            </Col>
            <Col offset={1} span={5}>
              {`${percent}%`}
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <div>
      <div style={{ fontSize: 20 }}> Merchant Rank</div>
      <div>
        <Row gutter={16}>
          <Col span={12}>
            <Statistic title={`Total Amount (${MainCurrency})`} value={totalAmount} precision={2} />
          </Col>
          <Col span={12}>
            <Statistic title="Completed Order Rate" value={completedPercent} suffix="%" />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Table
              style={{
                minHeight: 335,
              }}
              dataSource={columnsData}
              columns={columns}
              rowKey="merchantCode"
              size="middle"
              pagination={{
                pageSize: 5,
                defaultPageSize: 5,
              }}
                // loading={loading}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default MerchantRankTable;
