/* eslint-disable comma-dangle */
import React, { useEffect, useState } from 'react';
import useIdentity from '../../hooks/useIdentity';

function useGetParam() {
  const [param, setParam] = useState({});
  const { identity, merchantId } = useIdentity();

  const params = {
    'ds0.mtid': merchantId
  };
  const paramsAsString = JSON.stringify(params);
  const encodedParams = encodeURIComponent(paramsAsString);

  useEffect(() => {
    if (encodedParams.param === {}) return;
    setParam(encodedParams);
  }, [merchantId]);

  return {
    param
  };
}

export default useGetParam;
