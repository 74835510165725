/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable comma-dangle */
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { message } from 'antd';
import { selectUserInfo, setUserInfo } from '../../../features/userSlice';
import useIdentity from '../../../hooks/useIdentity';
import { authGoogle } from '../../../utils/firebase-config-google';
import { GOOGLE_AUTH_HD_PARAM } from '../../../utils/constants';

const useGoogleLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getIdentity } = useIdentity();

  const signInWithGoogle = async () => {
    // 目前使用 google 做第三方登入
    const provider = new GoogleAuthProvider();
    // 限制只有帶 ckex.tech 才會出現在選項
    provider.setCustomParameters({
      hd: GOOGLE_AUTH_HD_PARAM
    });
    try {
      const response = await signInWithPopup(authGoogle, provider);
      const credential = GoogleAuthProvider.credentialFromResult(response);
      const { accessToken } = authGoogle.currentUser;
      const payload = {
        user: getIdentity(accessToken),
        accessToken,
        apiKey: authGoogle.config.apiKey,
        aud: jwt_decode(accessToken).aud
      };
      dispatch(setUserInfo(payload));
      // 登入成功就轉址
      navigate('/member/change-password');
      message.success('login successfully');
      sessionStorage.setItem('accessToken', accessToken);
    } catch (error) {
      message.error('login failed: ', error);
    }
  };

  return { signInWithGoogle };
};

export default useGoogleLogin;
