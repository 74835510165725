/* eslint-disable comma-dangle */
/* eslint-disable no-param-reassign */
import { message } from 'antd';
import { createSlice } from '@reduxjs/toolkit';
import { getAllTradesApi } from '../Api/tradeAPI';

export const tradeSlice = createSlice({
  name: 'trade',
  initialState: {
    trade: {}
  },
  reducers: {
    setTrade: (state, action) => {
      state.trade = action.payload;
    }
  }
});

// actions
export const { setTrade } = tradeSlice.actions;

// thunk
export const getTradesAsync = (params) => async (dispatch) => {
  try {
    // fetch api
    const { setTradeLoading } = params;
    setTradeLoading(true);
    const response = await getAllTradesApi(params);
    const payload = response.data;
    dispatch(setTrade(payload));
    setTradeLoading(false);
  } catch (error) {
    message.error(error);
  }
};

// 原本要在 component 中拿出的 state, 先在這邊拿出
export const selectTrade = (state) => state.trade;

export default tradeSlice.reducer;
