import React from 'react';

function NotFoundPage() {
  return (
    <div>
      404 not found
    </div>
  );
}

export default NotFoundPage;
