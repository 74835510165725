/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable comma-dangle */
import { Form, Input } from 'antd';
import React from 'react';

import RememberMe from './RememberMe/RememberMe';
import useLogin from './useLogin';
import GoogleLogin from './GoogleLogin/GoogleLogin';
import EmailAndPasswordLogin from './EmailAndPasswordLogin/EmailAndPasswordLogin';
import {
  ENCODED_BACKOFFICE_D9E32_AECRICEX_ONE,
  ENCODED_LOCALHOST
} from '../../utils/constants';

function Login() {
  const {
    loginEmail,
    setLoginEmail,
    loginPassword,
    setLoginPassword,
    hostname,
    onFinish,
    onFinishFailed
  } = useLogin();

  return (
    <Form
      name="basic"
      labelCol={{
        span: 8
      }}
      wrapperCol={{
        span: 16
      }}
      initialValues={{
        remember: true
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="on"
    >
      {(hostname !== ENCODED_BACKOFFICE_D9E32_AECRICEX_ONE &&
        hostname !== ENCODED_LOCALHOST) && (
        <>
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your username!'
              }
            ]}
            type="email"
            value={loginEmail}
            onChange={(e) => {
              setLoginEmail(e.target.value);
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!'
              }
            ]}
            type="password"
            value={loginPassword}
            onChange={(e) => {
              setLoginPassword(e.target.value);
            }}
          >
            <Input.Password />
          </Form.Item>
          {/* <RememberMe /> */}
          <EmailAndPasswordLogin
            loginEmail={loginEmail}
            loginPassword={loginPassword}
          />
        </>
      )}
      {(hostname === ENCODED_BACKOFFICE_D9E32_AECRICEX_ONE ||
        hostname === ENCODED_LOCALHOST) && <GoogleLogin />}
    </Form>
  );
}

export default Login;
