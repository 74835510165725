/* eslint-disable arrow-body-style */
/* eslint-disable indent */
/* eslint-disable camelcase */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable object-curly-newline */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */
import { Col, Layout, Row, Button } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../features/userSlice';
import TradeFilter from '../../../components/TradeFilter/TradeFilter';
import useConstants from '../../../hooks/useConstants';

const { Content } = Layout;

function TradePage() {
  const userInfo = useSelector(selectUserInfo);
  const { FUND_LOG_IFRAME_URL } = useConstants();

  const handleAppSmithClick = () => {
    window.open(FUND_LOG_IFRAME_URL, 'Fund log');
  };

  return (
    <div className="site-layout-content">
      <Row
        style={{
          justifyContent: 'left'
        }}
      >
        {/* <TradeFilter/> */}
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <div
            className="grid place-items-center"
            style={{
              minWidth: '360px',
              width: '100%',
              minHeight: '800px',
              height: '100%'
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleAppSmithClick}
            >
              新開 Fund log 分頁
            </Button>
            {/* <iframe
              title="another-back-office"
              frameBorder="0"
              width="100%"
              height="100%"
              src={FUND_LOG_IFRAME_URL}
            /> */}
            {/* <TradeForm /> */}
          </div>
        </Col>
        <Col
          span={4}
          offset={8}
          style={{
            marginTop: '20px'
          }}
        />
      </Row>
    </div>
  );
}

export default TradePage;
