/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useRef } from 'react';
import {
  Button, Input, message, Space,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
// eslint-disable-next-line import/named
import { firestoreMTS } from '../../../utils/firestore-config-mts';

function useOrderList() {
  const [modalData, setModalData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idSearch, setIDSearch] = useState('');
  const [idSearchedColumn, setIDSearchedColumn] = useState('');
  const idSearchInput = useRef(null);
  const [userIDSearch, setUserIDSearch] = useState('');
  const [userIDSearchedColumn, setUserIDSearchedColumn] = useState('');
  const userIDSearchInput = useRef(null);
  const [usernameSearch, setUsernameSearch] = useState('');
  const [usernameSearchedColumn, setUsernameSearchedColumn] = useState('');
  const usernameSearchInput = useRef(null);
  const [ipSearch, setIPSearch] = useState('');
  const [ipSearchedColumn, setIPSearchedColumn] = useState('');
  const ipSearchInput = useRef(null);
  const [merchantCodeSearch, setMerchantCodeSearch] = useState('');
  const [merchantCodeSearchedColumn, setMerchantCodeSearchedColumn] = useState('');
  const merchantCodeSearchInput = useRef(null);
  const [eventIDSearch, setEventIDSearch] = useState('');
  const [eventIDSearchedColumn, setEventIDSearchedColumn] = useState('');
  const eventIDSearchInput = useRef(null);
  const [ticketIDSearch, setTicketIDSearch] = useState('');
  const [ticketIDSearchedColumn, setTicketIDSearchedColumn] = useState('');
  const ticketIDSearchInput = useRef(null);
  const [ogTicketIDSearch, setOgTicketIDSearch] = useState('');
  const [ogTicketIDSearchedColumn, setOgTicketIDSearchedColumn] = useState('');
  const ogTicketIDSearchInput = useRef(null);
  const [marketIDSearch, setMarketIDSearch] = useState('');
  const [marketIDSearchedColumn, setMarketIDSearchedColumn] = useState('');
  const marketIDSearchInput = useRef(null);

  // eslint-disable-next-line max-len
  const ticketIDFilter = (value, record) => record.ticket?.TicketId.toString().toLowerCase().includes(value.toLowerCase());
  const eventIDFilter = (value, record) => {
    if (!record.originTicket?.Selections) return false;
    // eslint-disable-next-line no-restricted-syntax
    for (const bet of record.originTicket.Selections) {
      if (bet.EventId.toString().toLowerCase().includes(value.toLowerCase())) return true;
    }
    return false;
  };
  // eslint-disable-next-line max-len
  const ogTicketIDFilter = (value, record) => record.originTicket?.TicketId.toString().toLowerCase().includes(value.toLowerCase());
  // eslint-disable-next-line max-len
  const marketIDFilter = (value, record) => {
    let find = false;
    record.details && record.details.forEach((detail) => {
      if (detail.MarketId.toString().toLowerCase().includes(value.toLowerCase())) {
        find = true;
      }
    });
    return find;
  };

  // eslint-disable-next-line max-len,no-use-before-define
  const getMarketIDSearchProps = () => getColumnSearchProps(
    'details',
    marketIDSearch,
    marketIDSearchInput,
    setMarketIDSearch,
    setMarketIDSearchedColumn,
    marketIDSearchedColumn,
    marketIDFilter,
  );

  // eslint-disable-next-line max-len,no-use-before-define
  const getEventIDSearchProps = () => getColumnSearchProps(
    'ticket',
    eventIDSearch,
    eventIDSearchInput,
    setEventIDSearch,
    setEventIDSearchedColumn,
    eventIDSearchedColumn,
    eventIDFilter,
  );

  // eslint-disable-next-line max-len,no-use-before-define
  const getOriginTicketIDSearchProps = () => getColumnSearchProps(
    'originTicket',
    ogTicketIDSearch,
    ogTicketIDSearchInput,
    setOgTicketIDSearch,
    setOgTicketIDSearchedColumn,
    ogTicketIDSearchedColumn,
    ogTicketIDFilter,
  );

  // eslint-disable-next-line max-len,no-use-before-define
  const getTicketIDSearchProps = () => getColumnSearchProps(
    'ticket',
    ticketIDSearch,
    ticketIDSearchInput,
    setTicketIDSearch,
    setTicketIDSearchedColumn,
    ticketIDSearchedColumn,
    ticketIDFilter,
  );

  // eslint-disable-next-line max-len,no-use-before-define
  const getMerchantCodeSearchProps = () => getColumnSearchProps(
    'merchantCode',
    merchantCodeSearch,
    merchantCodeSearchInput,
    setMerchantCodeSearch,
    setMerchantCodeSearchedColumn,
    merchantCodeSearchedColumn,
  );
  // eslint-disable-next-line max-len,no-use-before-define
  const getIPSearchProps = () => getColumnSearchProps(
    'ip',
    ipSearch,
    ipSearchInput,
    setIPSearch,
    setIPSearchedColumn,
    ipSearchedColumn,
  );
  // eslint-disable-next-line max-len,no-use-before-define
  const getUsernameSearchProps = () => getColumnSearchProps(
    'username',
    usernameSearch,
    usernameSearchInput,
    setUsernameSearch,
    setUsernameSearchedColumn,
    usernameSearchedColumn,
  );
  // eslint-disable-next-line max-len,no-use-before-define
  const getIDSearchProps = () => getColumnSearchProps(
    'id',
    idSearch,
    idSearchInput,
    setIDSearch,
    setIDSearchedColumn,
    idSearchedColumn,
  );
  // eslint-disable-next-line no-use-before-define
  const getUserIDSearchProps = () => getColumnSearchProps(
    'userId',
    userIDSearch,
    userIDSearchInput,
    setUserIDSearch,
    setUserIDSearchedColumn,
    userIDSearchedColumn,
  );
  const getColumnSearchProps = (
    dataIndex,
    searchText,
    searchInput,
    setSearchText,
    setSearchedColumn,
    searchedColumn,
    customFilterFunc = null,
  ) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => {
            confirm();
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
              setUserIDSearch(selectedKeys[0]);
              setUserIDSearchedColumn(dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                clearFilters();
                setSearchText('');
              }
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    // eslint-disable-next-line max-len
    onFilter: customFilterFunc != null ? customFilterFunc : (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  return {
    modalData,
    setModalData,
    isModalOpen,
    setIsModalOpen,
    getIPSearchProps,
    getIDSearchProps,
    getUserIDSearchProps,
    getEventIDSearchProps,
    getUsernameSearchProps,
    getMarketIDSearchProps,
    getTicketIDSearchProps,
    getMerchantCodeSearchProps,
    getOriginTicketIDSearchProps,
  };
}

export default useOrderList;
