/* eslint-disable */
import React, { useEffect } from 'react';
import { Card, Row, Col } from 'antd';
import ReactECharts from 'echarts-for-react';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import useDashBoard from './useDashBoard';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../features/userSlice';
// import useToken from '../../../hooks/useToken';

function Dashboard() {
  const { cpuOption, conOption, netOption, memOption } = useDashBoard();

  return (
    <Row style={{ margin: '0 -12px' }}>
      <Col span={12} style={{ padding: '0 12px' }}>
        <Card>
          <ReactECharts
            style={{ height: '348px', width: '100%' }}
            option={conOption}
          />
        </Card>
      </Col>
      <Col span={12} style={{ padding: '0 12px' }}>
        <Card>
          <ReactECharts
            style={{ height: '348px', width: '100%' }}
            option={netOption}
          />
        </Card>
      </Col>

      <Col span={12} style={{ padding: '0 12px', marginTop: '16px' }}>
        <Card>
          <ReactECharts
            style={{ height: '348px', width: '100%' }}
            option={cpuOption}
          />
        </Card>
      </Col>
      <Col span={12} style={{ padding: '0 12px', marginTop: '16px' }}>
        <Card>
          <ReactECharts
            style={{ height: '348px', width: '100%' }}
            option={memOption}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default Dashboard;
