/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react';
import ReactHlsPlayer from "react-hls-player";
import axios from "axios";
import {Dropdown, Menu, Space} from "antd";
import {DownOutlined, SmileOutlined} from "@ant-design/icons";



const menu = (
    <Menu
        items={[
            {
                key: '1',
                label: (
                    <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                        1st menu item
                    </a>
                ),
            },
            {
                key: '2',
                label: (
                    <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                        2nd menu item (disabled)
                    </a>
                ),
                icon: <SmileOutlined />,
                disabled: true,
            },
            {
                key: '3',
                label: (
                    <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                        3rd menu item (disabled)
                    </a>
                ),
                disabled: true,
            },
            {
                key: '4',
                danger: true,
                label: 'a danger item',
            },
        ]}
    />
);
function HLSPlayer() {
    const [hlsUrl, setHlsUrl] = useState([]);

    useEffect(async () => {
        const res = await axios.get(`${process.env.REACT_APP_RELAY_SERVER_URL_PROD}api/streams`)
        let streams = []
        for (const [key, value] of Object.entries(res.data.live)) {
            streams.push({streamKey : key, info: value})
        }
        setHlsUrl(streams)
    }, [])
  return (
      <div style={{
          display: "flex",
          flexDirection: "row"
      }}>
          {
              hlsUrl.map((stream) => {
                      return (
                          <div className="">
                              <ReactHlsPlayer
                                  key={stream.streamKey}
                                  src={`${process.env.REACT_APP_RELAY_SERVER_URL_PROD}/live/${stream.streamKey}/index.m3u8`}
                                  autoPlay={true}
                                  muted={true}
                                  controls={true}
                                  width="70%"
                                  height="auto"
                                  style={{
                                      margin: 5
                                  }}
                                  hlsConfig={{
                                      maxLoadingDelay: 5,
                                      xhrSetup: function (xhr, url) {

                                      },
                                      fetchSetup: function (context, initParams) {
                                          // Always send cookies, even for cross-origin calls.
                                          initParams.credentials = 'include';
                                          return new Request(context.url, initParams);
                                      },
                                  }}
                              />
                              <div>Stream Key: {stream.streamKey}</div>
                          </div>
                  )})
          }

      </div>
  );
}

export default HLSPlayer;
