/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable comma-dangle */
import { Table } from 'antd';
import React from 'react';
import useGetOrder from './useGetOrder';

function OrderForm(props) {
  const { trade } = props;
  const { columns, data, onChange } = useGetOrder(trade);
  return (
    <Table
      columns={columns}
      dataSource={data}
      onChange={onChange}
      pagination={{ pageSize: 100 }}
      style={{ fontSize: '5px' }}
      scroll={{
        x: 1500
      }}
    />
  );
}

export default OrderForm;
