import axios from 'axios';

export const getMappingList = (env, eventTypeId, before, after) => {
  const url = env === 'dev'
    ? `${process.env.REACT_APP_ADMIN_API_URL_DEV}list-unsettle-event-by-event-type-id/?event_type_id=${eventTypeId}&days_before=${before}&days_after=${after}`
    : `${process.env.REACT_APP_ADMIN_API_URL_PROD}list-unsettle-event-by-event-type-id/?event_type_id=${eventTypeId}&days_before=${before}&days_after=${after}`;
  const token = env === 'dev'
    ? `bearer ${process.env.REACT_APP_ADMIN_API_KEY_DEV}`
    : `bearer ${process.env.REACT_APP_ADMIN_API_KEY_PROD}`;
  return axios.get(url, { headers: { authorization: token } });
};
export const updateMatchId = (env, eventId, type, matchId) => {
  const domain = env === 'dev'
    ? process.env.REACT_APP_ADMIN_API_URL_DEV
    : process.env.REACT_APP_ADMIN_API_URL_PROD;
  const token = env === 'dev'
    ? `bearer ${process.env.REACT_APP_ADMIN_API_KEY_DEV}`
    : `bearer ${process.env.REACT_APP_ADMIN_API_KEY_PROD}`;
  return axios.post(
    `${domain}mapping/match-id/update?brand=${type}&betfair_event_id=${eventId}&match_id=${matchId}`,
    {},
    {
      headers: {
        authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
};
export const getRadarList = (sort) => axios.get(
  `${process.env.REACT_APP_RADAR_API_URL}api/list?lng=en&key=${process.env.REACT_APP_RADAR_API_KEY}&sort=${sort}`,
);
