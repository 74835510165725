/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable comma-dangle */
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTrade } from '../../../features/tradeSlice';

const useGetTrade = () => {
  const { trade } = useSelector(selectTrade);
  const allTrades = trade?.result;

  const [totalCommission, setTotalCommission] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalTradeAmount, setTotalTradeAmount] = useState(0);
  const [totalValidTrade, setTotalValidTrade] = useState(0);

  useEffect(() => {
    setTotalCommission(trade?.total?.commission);
    setTotalProfit(trade?.total?.profit);
    setTotalTradeAmount(trade?.total?.trade_amount);
    setTotalValidTrade(trade?.total?.valid_trade);
  }, [trade?.count]);

  const getData = (perTrade) => {
    const result = {
      merchant: perTrade?.merchant_code,
      key: perTrade?.trade_id,
      tradeID: perTrade?.trade_id,
      playerID: perTrade?.player_id,
      userID: perTrade?.user_id,
      eventID: perTrade?.event_id,
      marketID: perTrade?.market_id,
      event: perTrade?.event_name?.en,
      market: perTrade?.market_name?.en,
      tradeAmount: perTrade?.trade_amount,
      validTrade: perTrade?.valid_trade,
      profit: perTrade?.profit,
      commission: perTrade?.commission,
      result: perTrade?.outcome_name?.en,
      updateTime: perTrade?.update_date,
      settleTime: perTrade?.settle_order_dt,
      tradeDetail: perTrade?.trade_detail,
      orderCount: perTrade?.trade_detail?.length,
      expandable: true
    };
    return result;
  };

  const sums = {
    key: uuidv4(),
    dataIndex: 'Total',
    merchant: 'Total',
    commission: totalCommission,
    profit: totalProfit,
    tradeAmount: totalTradeAmount,
    validTrade: totalValidTrade
  };

  const getDatas = (trades) => {
    const result = [];
    trades?.forEach((perTrade) => result.push(getData(perTrade)));
    if (allTrades?.length === 0 || !allTrades) return result;
    return [...result, sums];
  };

  const columns = [
    {
      title: 'Merchant',
      dataIndex: 'merchant',
      fixed: 'left',
      width: 100
    },
    {
      title: 'TradeID',
      dataIndex: 'tradeID',
      // fixed: 'left',
      width: 400
    },
    {
      title: 'PlayerID',
      dataIndex: 'playerID',
      width: 200
    },
    {
      title: 'UserID',
      dataIndex: 'userID',
      width: 200
    },
    {
      title: 'EventID',
      dataIndex: 'eventID',
      width: 130
    },
    {
      title: 'MarketID',
      dataIndex: 'marketID',
      width: 140
    },
    {
      title: 'Event',
      dataIndex: 'event',
      width: 400
    },
    {
      title: 'Market',
      dataIndex: 'market',
      width: 200
    },
    {
      title: 'Trade amount',
      dataIndex: 'tradeAmount',
      width: 140
    },
    {
      title: 'Valid trade',
      dataIndex: 'validTrade',
      width: 140
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      width: 140
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      width: 140
    },
    {
      title: 'Result',
      dataIndex: 'result',
      width: 120
    },
    {
      title: 'Update time (+0)',
      dataIndex: 'updateTime',
      width: 250
    },
    {
      title: 'Settle time (+0)',
      dataIndex: 'settleTime',
      width: 250
      // sorter: {
      //   compare: (a, b) => new Date(a.settleTime) - new Date(b.settleTime),
      //   multiple: 1
      // }
    },
    {
      title: 'Order count',
      dataIndex: 'orderCount',
      width: 100
    }
  ];

  const data = getDatas(allTrades);

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return {
    columns,
    data,
    onChange
  };
};

export default useGetTrade;
