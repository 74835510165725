/* eslint-disable */
import React, { useEffect } from 'react';
import { Card, Table, Modal } from 'antd';
import Flvplayer from './Flvplayer';
import useStreams from './useStreams';

const openVideo = (record) => {
  Modal.info({
    icon: null,
    title: 'Video Player',
    width: 640,
    height: 480,
    content: (
      <Flvplayer
        url={`${process.env.REACT_APP_RELAY_SERVER_URL_PROD}${record.app}/${record.name}.flv`}
        type="flv"
      />
    )
  });
};

const columns = [
  {
    title: 'Game',
    children: [
      {
        title: 'Sport',
        dataIndex: 'sport',
        key: 'sport'
      },
      {
        title: 'League',
        dataIndex: 'league',
        key: 'league'
      },
      {
        title: 'Team A',
        dataIndex: 'teamA',
        key: 'teamA'
      },
      {
        title: 'Team B',
        dataIndex: 'teamB',
        key: 'teamB'
      },
      {
        title: 'Start Time',
        dataIndex: 'startTime',
        key: 'startTime'
      },
    ]
  },
  {
    title: 'App',
    dataIndex: 'app',
    key: 'app'
  },
  {
    title: 'StreamKey',
    dataIndex: 'name',
    key: 'name',
    render: (name, record) => (
        <a href="##" onClick={() => openVideo(record)}>
          {name}
        </a>
    )
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time'
  },
];
function Streams() {
  const { streamsData, loading } = useStreams();

  return (
    <Card
      style={{
        overflow: 'auto'
      }}
    >
      <Table
        dataSource={streamsData}
        columns={columns}
        loading={loading}
        bordered
        small
        pagination={false}
      />
    </Card>
  );
}

export default Streams;
