import React from 'react';
import { Table, Transfer } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import difference from 'lodash/difference';

const leftTableColumns = [
  {
    dataIndex: 'title',
    title: 'Current White IPs',
  },
];
const rightTableColumns = [
  {
    dataIndex: 'title',
    title: 'To be removed IPs',
  },
];

// Customize Table Transfer
function TableTransfer({ leftColumns, rightColumns, ...restProps }) {
  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    <Transfer {...restProps}>
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;
        const rowSelection = {
          getCheckboxProps: (item) => ({
            disabled: listDisabled || item.disabled,
          }),

          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter((item) => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },

          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },

          selectedRowKeys: listSelectedKeys,
        };
        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            style={{
              pointerEvents: listDisabled ? 'none' : undefined,
            }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
          />
        );
      }}
    </Transfer>
  );
}

function TransferSection(props) {
  const {
    whitelist,
    setTargetKeys,
    targetKeys,
    loading,
  } = props;
  const whitelistData = whitelist.map((item, i) => ({
    key: i.toString(),
    title: item,
  }));

  const onChange = (nextTargetKeys) => setTargetKeys(nextTargetKeys);

  const filterFunc = (inputValue, item) => item.title.indexOf(inputValue) !== -1;

  return (
    <TableTransfer
      dataSource={whitelistData}
      targetKeys={targetKeys}
      showSearch
      onChange={onChange}
      disabled={loading}
      pagination={{ pageSize: 15, simple: true }}
      filterOption={(inputValue, item) => filterFunc(inputValue, item)}
      leftColumns={leftTableColumns}
      rightColumns={rightTableColumns}
      listStyle={{
        width: 250,
      }}
    />
  );
}

export default TransferSection;
