import React from 'react';
import { gapi } from 'gapi-script';

function useGapi() {
  // Google api 套件 Oauth 2.0 初始化
  const gapiAuthInit = () => {
    gapi.load('client:auth2', () => {
      gapi.auth2.init(
        { client_id: process.env.REACT_APP_GCP_ARMOR_OAUTH_CLIENT_ID },
      );
    });
  };

  const gapiAuthSignIn = async () => {
    await gapi.auth2.getAuthInstance()
      .signIn({
        scope: 'https://www.googleapis.com/auth/cloud-platform https://www.googleapis.com/auth/compute https://www.googleapis.com/auth/compute.readonly',
      });
  };

  const gapiLoadRestApi = async () => {
    await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/compute/v1/rest');
  };

  const gapiGetSecurityPolicyRule = (policyInfo) => (
    gapi.client.compute.securityPolicies.getRule(policyInfo)
  );

  const gapiUpdateSecurityPolicyRule = (policyUpdateInfo) => (
    gapi.client.compute.securityPolicies.patchRule(policyUpdateInfo)
  );

  const gapiGetSecurityPolicy = (policyInfo) => (
    gapi.client.compute.securityPolicies.get(policyInfo)
  );

  const gapiInsertSecurityPolicyRule = (policyInfo) => (
    gapi.client.compute.securityPolicies.addRule(policyInfo)
  );

  const gapiDeleteSecurityPolicyRule = (policyInfo) => (
    gapi.client.compute.securityPolicies.removeRule(policyInfo)
  );

  return {
    gapiUpdateSecurityPolicyRule,
    gapiAuthInit,
    gapiGetSecurityPolicyRule,
    gapiLoadRestApi,
    gapiAuthSignIn,
    gapiGetSecurityPolicy,
    gapiInsertSecurityPolicyRule,
    gapiDeleteSecurityPolicyRule,
  };
}

export default useGapi;
