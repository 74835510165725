import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const getEventApi = (env, eventId) => {
  const url = env === 'dev'
    ? `${process.env.REACT_APP_QUOTE_API_URL_DEV}match-detail/${eventId}`
    : `${process.env.REACT_APP_QUOTE_API_URL_PROD}match-detail/${eventId}`;
  const token = env === 'dev'
    ? `bearer ${process.env.REACT_APP_QUOTE_API_KEY_DEV}`
    : `bearer ${process.env.REACT_APP_QUOTE_API_KEY_PROD}`;
  return axios.get(url, { headers: { authorization: token } });
};

export const closeMarketApi = (env, eventId, marketId) => {
  const url = env === 'dev'
    ? `${process.env.REACT_APP_ADMIN_API_URL_DEV}set-chloe-market-status`
    : `${process.env.REACT_APP_ADMIN_API_URL_PROD}set-chloe-market-status`;
  const token = env === 'dev'
    ? `bearer ${process.env.REACT_APP_ADMIN_API_KEY_DEV}`
    : `bearer ${process.env.REACT_APP_ADMIN_API_KEY_PROD}`;
  return axios.post(
    url,
    {
      event_id: eventId,
      market_id: marketId,
      status: 'close',
    },
    {
      headers: {
        authorization: token,
      },
    },
  );
};

export const closeEventApi = (env, eventId) => {
  const url = env === 'dev'
    ? `${process.env.REACT_APP_ADMIN_API_URL_DEV}close-all-chloe-market-by-event-id`
    : `${process.env.REACT_APP_ADMIN_API_URL_PROD}close-all-chloe-market-by-event-id`;
  const token = env === 'dev'
    ? `bearer ${process.env.REACT_APP_ADMIN_API_KEY_DEV}`
    : `bearer ${process.env.REACT_APP_ADMIN_API_KEY_PROD}`;
  const bodyFormData = new FormData();
  bodyFormData.append('event_id', eventId);
  return axios.post(
    url,
    bodyFormData,
    {
      headers: {
        authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};
