/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getExposureApi } from '../Api/exposureAPI';

export const exposureSlice = createSlice({
  name: 'exposure',
  initialState: {
    exposure: {},
  },
  reducers: {
    setExposure: (state, action) => {
      state.exposure = action.payload;
    },
  },
});

// actions
export const { setExposure } = exposureSlice.actions;

// thunk
export const getExposureAsync = () => async (dispatch) => {
  try {
    // fetch api
    const response = await getExposureApi();
    const payload = response?.data?.result;
    dispatch(setExposure(payload));
  } catch (error) {
    console.log(error);
    // dispatch(setError(error.response.data.error));
  }
};

// 原本要在 component 中拿出的 state, 先在這邊拿出
export const selectExposure = (state) => state.exposure.exposure;

export default exposureSlice.reducer;
