import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect } from 'react';

const useOnAuthStateChanged = (authApp) => {
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authApp, (currentUser) => {});

    return () => {
      unsubscribe();
    };
  }, []);
};

export default useOnAuthStateChanged;
