import { initializeApp } from 'firebase/app';

// eslint-disable-next-line import/prefer-default-export
export const firestoreMTS = initializeApp({
  apiKey: 'AIzaSyBAKglvt8DA3lNQ22iAYmLHXJIRK-4Tky4',
  authDomain: 'ckex-319603.firebaseapp.com',
  projectId: 'ckex-319603',
  storageBucket: 'ckex-319603.appspot.com',
  messagingSenderId: '222996038368',
  appId: '1:222996038368:web:bf4eb81ec83965c8344685',
});
