/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useRef } from 'react';
import { message } from 'antd';
import axios from 'axios';
// eslint-disable-next-line import/named
import { closeMarketApi, getEventApi, closeEventApi } from '../../../Api/riskAPI';

function useEventSearch() {
  const [data, setData] = useState([]);
  const [expandData, setExpandData] = useState([]);
  const [expandProductData, setExpandProductData] = useState([]);
  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [env, setEnv] = useState('dev');
  const [eventID, setEventID] = useState('');
  const [selectedMarketRowKeys, setSelectedMarketRowKeys] = useState([]);

  // eslint-disable-next-line no-shadow
  const fetch = async () => {
    try {
      if (!eventID) {
        message.error('Empty Input');
        return;
      }
      setLoading(true);
      const res = await getEventApi(env, eventID);
      if (!res.data.event_id) {
        message.error(`Event ID: ${eventID} not Found`);
        setLoading(false);
        return;
      }
      setData([res.data]);
      setLoading(false);
    } catch (e) {
      message.error(e);
    }
  };

  const closeAllProducts = async () => {
    try {
      const res = await closeMarketApi(env, eventID, modalData.market_id);
      if (res.status >= 300) {
        message.error('Close All Products Error');
        return;
      }
      message.success('Close All Products Successfully');
    } catch (e) {
      message.error(e);
    }
  };

  const closeMarkets = async () => {
    await selectedMarketRowKeys.forEach(async (marketId) => {
      try {
        const res = await closeMarketApi(env, eventID, marketId);
        if (res.status >= 300) {
          message.error(`Close Market ID ${marketId} Error`);
        }
        message.success(`Close Market ID ${marketId} Successfully`);
      } catch (e) {
        message.error(`Close Market ID ${marketId} Error: ${e}`);
      }
    });
    setSelectedMarketRowKeys([]);
  };

  const closeAllMarkets = async () => {
    try {
      const res = await closeEventApi(env, modalData.event_id);
      if (res.status >= 300) {
        message.error('Close All Markets Error');
        return;
      }
      message.success('Close All Markets Successfully');
    } catch (e) {
      message.error(e);
    }
  };

  return {
    loading,
    setLoading,
    env,
    setEnv,
    eventID,
    setEventID,
    data,
    setData,
    modalData,
    setModalData,
    isModalOpen,
    setIsModalOpen,
    expandData,
    setExpandData,
    expandProductData,
    fetch,
    closeAllProducts,
    closeAllMarkets,
    closeMarkets,
    selectedMarketRowKeys,
    setSelectedMarketRowKeys,
  };
}

export default useEventSearch;
