/* eslint-disable consistent-return */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
import { Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import routes from '../../ routes';
import { selectUserInfo } from '../../features/userSlice';
import useRouter from '../../hooks/useRouter';
import { ADMIN, MTBO, MTXX } from '../../utils/constants';

function SideBar() {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState('');
  const { pathname } = useRouter();

  const userInfo = useSelector(selectUserInfo);
  const { user } = userInfo;

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label
    };
  }

  const itemsForMtbo = routes.map((route) => {
    if (!route.label || !route.identity.includes(MTBO)) return;
    return route.children
      ? getItem(
          route.label,
          route.path,
          route.icon,
          route.children.map((child) =>
            getItem(child.label, `${route.path}/${child.path}`, child.icon)
          )
        )
      : getItem(route.label, route.path, route.icon);
  });

  const itemsForMtxx = routes.map((route) => {
    if (!route.label || !route.identity.includes(MTXX)) return;
    return route.children
      ? getItem(
          route.label,
          route.path,
          route.icon,
          route.children.map((child) =>
            getItem(child.label, `${route.path}/${child.path}`, child.icon)
          )
        )
      : getItem(route.label, route.path, route.icon);
  });

  const itemsForAdmin = routes.map((route) => {
    if (!route.label) return;
    return route.children
      ? getItem(
          route.label,
          route.path,
          route.icon,
          route.children.map((child) =>
            getItem(child.label, `${route.path}/${child.path}`, child.icon)
          )
        )
      : getItem(route.label, route.path, route.icon);
  });

  useEffect(() => {
    setSelectedKey(pathname.slice(1));
  }, [pathname]);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="logo" />
      {user === ADMIN && (
        <Menu
          theme="dark"
          defaultSelectedKeys={[pathname.slice(1)]}
          selectedKeys={[selectedKey]}
          mode="inline"
          items={itemsForAdmin}
        />
      )}
      {user === MTBO && (
        <Menu
          theme="dark"
          defaultSelectedKeys={[pathname.slice(1)]}
          selectedKeys={[selectedKey]}
          mode="inline"
          items={itemsForMtbo}
        />
      )}
      {user === MTXX && (
        <Menu
          theme="dark"
          defaultSelectedKeys={[pathname.slice(1)]}
          selectedKeys={[selectedKey]}
          mode="inline"
          items={itemsForMtxx}
        />
      )}
    </Sider>
  );
}

export default SideBar;
