/* eslint-disable comma-dangle */
import { Col, Row, Table } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TradeFilter from '../../components/TradeFilter/TradeFilter';
import { getExposureAsync, selectExposure } from '../../features/exposureSlice';
import ExposureForm from './ExposureForm/ExposureForm';

function ExposurePage() {
  const { exposure } = useSelector(selectExposure);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExposureAsync());
  }, []);

  return (
    <div className="site-layout-content">
      <Row
        style={{
          justifyContent: 'left'
        }}
      >
        <TradeFilter />
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <div
            className="grid place-items-center"
            style={{
              minWidth: '360px',
              width: '100%',
              minHeight: '800px',
              height: '100%',
              marginTop: '40px'
            }}
          >
            <ExposureForm />
          </div>
        </Col>
        <Col
          span={4}
          offset={8}
          style={{
            marginTop: '20px'
          }}
        />
      </Row>
    </div>
  );
}

export default ExposurePage;
