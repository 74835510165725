import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { bytesToSize, secondsToDhms } from '../../../utils/helper';

function useProfile() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    setLoading(true);
    const res = await axios.get(`${process.env.REACT_APP_RELAY_SERVER_URL_PROD}api/server`);
    const osInfo = { key: 0, name: 'OS', value: `${res.data.os.arch}_${res.data.os.platform}_${res.data.os.release}` };
    const cpuInfo = { key: 1, name: 'CPU', value: `${res.data.cpu.num} x ${res.data.cpu.model}` };
    const memInfo = { key: 2, name: 'Memory', value: bytesToSize(res.data.mem.totle) };
    const nodeInfo = { key: 3, name: 'Node.js', value: res.data.nodejs.version };
    const uptimeInfo = { key: 4, name: 'Uptime', value: secondsToDhms(res.data.nodejs.uptime) };
    const versionInfo = { key: 5, name: 'Version', value: res.data.version };
    setLoading(false);
    setData([osInfo, cpuInfo, memInfo, nodeInfo, uptimeInfo, versionInfo]);
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    loading,
    data,
  };
}

export default useProfile;
