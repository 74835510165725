/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import { Card, Modal, Table } from 'antd';
import Flvplayer from './Flvplayer';
import { secondsToDhmsSimple } from '../../../utils/helper';
import axios from "axios";
import {getRadarList} from "../../../Api/streamAPI";

function useStreams() {
  const [streamsData, setStreamsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const timer = useRef(null);

  // 與雷達比賽資訊列表 mapping (deprecation)
  const getGameInfo = async () => {
    let gameInfo = {}
    const radar = await getRadarList('live')
    radar.data.forEach(game => {
      gameInfo[game.StreamName] = {
        sport: game.SportEn,
        league : game.LigaEn,
        teamA: game.Opp1En,
        teamB: game.Opp2En,
        startTime: game.Time
      }
    })
    if (gameInfo.length === 0) return await getGameInfo()
    return gameInfo
  }

  const fetch = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_RELAY_SERVER_URL_PROD}api/streams`);
    const sData = [];
    let index = 0;
    for (const app in data) {
      for (const name in data[app]) {
        const stream = data[app][name].publisher;
        const clients = data[app][name].subscribers;
        if (stream) {
          const now = new Date().getTime() / 1000;
          const str = new Date(stream.connectCreated).getTime() / 1000;
          const streamData = {
            key: index++,
            app,
            name,
            id: stream.clientId,
            ip: stream.ip,
            ac: stream.audio ? `${stream.audio.codec} ${stream.audio.profile}` : '',
            freq: stream.audio ? stream.audio.samplerate : '',
            chan: stream.audio ? stream.audio.channels : '',
            vc: stream.video ? `${stream.video.codec} ${stream.video.profile}` : '',
            size: stream.video ? `${stream.video.width}x${stream.video.height}` : '',
            fps: stream.video ? Math.floor(stream.video.fps) : '',
            time: secondsToDhmsSimple(now - str),
            clients: clients.length,
          };
          sData.push(streamData);
        }
      }
    }
    // let gameInfo = await getGameInfo()
    // let combinedData = sData.map(data => !gameInfo[data.name] ? data : {...data, ...gameInfo[data.name]})
    setStreamsData(sData);
    setLoading(false);
  };

  useEffect(() => {
    fetch();
    timer.current = setInterval(() => fetch(), 10000);
    return () => clearInterval(timer.current);
  }, []);

  return {
    streamsData,
    loading,
  };
}

export default useStreams;
