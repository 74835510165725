/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {
    Card,
    Col,
    Row,
} from 'antd';
import useOMS from '../../../hooks/useOMS'
import useCurrency from '../../../hooks/useCurrency'
import OrderChart from './OrderChart/OrderChart'
import MerchantRankTable from "./MerchantRankTable/MerchantRankTable";
import EventPie from "./EventPie/EventPie";
import FetchDataBar from "../../../components/FetchDataBar/FetchDataBar";

function Dashboard() {
    const {
      currencyRates,
      MainCurrency,
      getMtsRate,
    } = useCurrency()

    const {
    data,
    setEnv,
    loading,
    setStartAt,
    setEndAt,
    startAt,
    endAt,
    count,
    isLive,
    setIsLive,
    fetch,
  } = useOMS()

  let barConfig = {
      setEnv,
      setStartAt,
      setEndAt,
      startAt,
      endAt,
      count,
      isLive,
      setIsLive,
      fetch: () => fetch({status: 'all'}),
  }
  useEffect(() => {
      fetch({status: 'all'});
  }, []);

  return (
    <div>
        <Card>
            <FetchDataBar {...barConfig}/>
        </Card>
        <Card style={{marginTop: 10}}>
            { !loading && <OrderChart data={data} getMtsRate={getMtsRate}/>}
        </Card>
        <Row gutter={16}>
            <Col span={12}>
                <Card style={{marginTop: 10}}>
                    { !loading && <EventPie data={data} currencyRates={currencyRates} getMtsRate={getMtsRate}/>}
                </Card>
            </Col>
            <Col span={12}>
                <Card style={{marginTop: 10}}>
                    { !loading && <MerchantRankTable data={data} count={count} currencyRates={currencyRates} getMtsRate={getMtsRate} MainCurrency={MainCurrency}/>}
                </Card>
            </Col>
        </Row>
    </div>
  );
}

export default Dashboard;
