/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
import { Button, Form } from 'antd';
import React from 'react';
import useOnAuthStateChanged from '../../../hooks/useOnAuthStateChanged';
import { authMtbo } from '../../../utils/firebase-config-mtbo';
import useEmailAndPasswordLogIn from './useEmailAndPasswordLogin';

function EmailAndPasswordLogin(props) {
  const { loginEmail, loginPassword } = props;
  const { signInWithEmailAccountAndPassword } = useEmailAndPasswordLogIn(
    loginEmail,
    loginPassword
  );
  useOnAuthStateChanged(authMtbo);

  return (
    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16
      }}
    >
      <Button
        type="primary"
        htmlType="submit"
        onClick={signInWithEmailAccountAndPassword}
      >
        Submit
      </Button>
    </Form.Item>
  );
}

export default EmailAndPasswordLogin;
