/* eslint-disable */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { matchRoutes } from 'react-router';
import Dashboard from './Dashboard/Dashboard';
import Login from './Login/Login';

function Layout({ children, routes }) {
  const location = useLocation();
  let allRoutes = [];
  routes.forEach((route) => {
    allRoutes.push({ path: route.path });
    if (route.children) {
      route.children.forEach((child) =>
        allRoutes.push({ path: route.path + '/' + child.path })
      );
    }
  });
  const [{ route }] = matchRoutes(allRoutes, location);
  switch (route.path) {
    case '':
      return <Login>{children}</Login>;
    default:
      return <Dashboard>{children}</Dashboard>;
  }
}

export default Layout;
