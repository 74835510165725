/* eslint-disable operator-linebreak */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from '../../../ routes';

function AdminRoutes() {
  return (
    <Routes>
      {routes.map((route) => (
        <Route key={route.path} path={route.path} element={route?.element}>
          {route.children &&
            route.children.map((child) => (
              <Route
                key={child.path}
                path={child.path}
                element={child.element}
              />
            ))}
        </Route>
      ))}
    </Routes>
  );
}

export default AdminRoutes;
