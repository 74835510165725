/* eslint-disable comma-dangle */
import { configureStore } from '@reduxjs/toolkit';
import tradeReducer from '../features/tradeSlice';
import userReducer from '../features/userSlice';
import exposureReducer from '../features/exposureSlice';

export default configureStore({
  reducer: {
    trade: tradeReducer,
    user: userReducer,
    exposure: exposureReducer
  }
});
