/* eslint-disable */
import React, {useEffect} from 'react';
import {Badge, Button, Card, Col, Dropdown, Input, Menu, Modal, Row, Select, Space, Table, Tag, Tooltip} from 'antd';
import moment from "moment/moment";
import useOMS from "../../../hooks/useOMS";
import FetchDataBar from "../../../components/FetchDataBar/FetchDataBar";
import useDashboard from "./useDashboard";
import useCurrency from "../../../hooks/useCurrency";
import {round} from "lodash";
import {Progress} from "@ant-design/charts";

function Dashboard() {
  const {
      eventSearch,
      eventSearchInput,
      setEventSearch,
      setEventSearchedColumn,
      eventSearchedColumn,
      getColumnSearchProps,
  } = useDashboard()

  const eventFilter = (value, record) => {
        return eventsDetail[record.event_id].event_name.toString().toLowerCase().includes(value.toLowerCase())
    };
  const {
     data,
     setEnv,
     loading,
     setStartAt,
     setEndAt,
     startAt,
     endAt,
     isRDLive,
     setIsRDLive,
     count,
     setData,
     setCount,
     eventsDetail,
     fetchCompleted,
  } = useOMS()

  const { formatCurrency } = useCurrency()

    useEffect(() => {
        fetchCompleted(false, true);
        return () => setData([])
    }, []);

    let barConfig = {
        setEnv,
        setStartAt,
        setEndAt,
        startAt,
        endAt,
        count,
        isLive: isRDLive,
        setIsLive: setIsRDLive,
        fetch: () => fetchCompleted(false, true),
    }

  const expandedProductRowRender = (record) => {
        const columns = [
            {
                title: 'Product ID',
                key: 'product_id',
                render: (_, { orders }) => orders[0].product_id
            },
            {
                title: 'Product',
                key: 'product_name',
                render: (_, { orders }) => eventsDetail[orders[0].event_id]?.markets[orders[0].market_id]?.chloe_products[orders[0].product_id]?.product_name
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (_, { orders }) => (
                    <Tag color='green' key={orders[0].product_id}>
                        {eventsDetail[orders[0].event_id]?.markets[orders[0].market_id]?.chloe_products[orders[0].product_id]?.status?.toUpperCase()}
                    </Tag>
                ),
            },
            {
                title: 'Expected Return',
                key: 'expected_return',
                sorter: (a, b) => b.return - a.return,
                render: (_, { orders }) => formatCurrency(orders.reduce((pre, cur) =>  pre + cur.return, 0))
            },
            {
                title: 'Potential Risk',
                key: 'potential_risk',
                sorter: (a, b) => b.risk - a.risk,
                render: (_, { orders }) => formatCurrency(orders.reduce((pre, cur) =>  pre + cur.risk, 0))
            },
            {
                title: 'Amount',
                key: 'amount',
                sorter: (a, b) => b.amount - a.amount,
                render: (_, { orders }) => formatCurrency(orders.reduce((pre, cur) =>  pre + cur.amount, 0))
            },
            {
                title: 'Count',
                key: 'count',
                render: (_, { orders }) => orders.length
            },
            {
                title: 'Percent',
                dataIndex: 'percent',
                key: 'percent',
                render: (item, { orders }, index) => {
                    const pRisk = orders.reduce((pre, cur) =>  pre + cur.risk, 0)
                    const percent = round( pRisk/ record.risk * 100, 2);
                    const config = {
                        height: 20,
                        width: 120,
                        autoFit: true,
                        percent: percent / 100,
                        color: ['#5B8FF9', '#E8EDF3'],
                    };
                    return (
                        <Row>
                            <Col span={18}>
                                <Progress {...config} />
                            </Col>
                            <Col offset={1} span={5}>
                                {`${percent}%`}
                            </Col>
                        </Row>
                    );
                },
            },
        ];
        return <Table
            columns={columns}
            dataSource={record.products}
            pagination={false}
            rowKey="key"
        />;
    };
  const expandedRowRender = (record) => {
        const columns = [
            {
                title: 'Market ID',
                dataIndex: 'market_id',
                key: 'market_id',
            },
            {
                title: 'Market Name',
                dataIndex: 'market_name',
                key: 'market_name',
                render: (_, market) => eventsDetail[record.event_id]?.markets[market.market_id]?.market_name,
            },
            {
                title: 'Market Label',
                dataIndex: 'market_label',
                width: '8%',
                key: 'market_name',
                render: (_, market) =>
                    eventsDetail[record.event_id]?.markets[market.market_id]?.market_label?.map(tag => (
                    <Tag color={tag.length > 5 ? 'geekblue' : 'green'} key={tag}>
                        {tag.toUpperCase()}
                    </Tag>)),
            },
            {
                title: 'Expected Return',
                key: 'expected_return',
                sorter: (a, b) => b.return - a.return,
                render: (_, record) => formatCurrency(record.return)
            },
            {
                title: 'Potential Risk',
                key: 'potential_risk',
                sorter: (a, b) => b.risk - a.risk,
                render: (_, {risk}) => formatCurrency(risk)
            },
            {
                title: 'Amount',
                key: 'amount',
                sorter: (a, b) => b.amount - a.amount,
                render: (_, {amount}) => formatCurrency(amount)
            },
            {
                title: 'Count',
                key: 'count',
                render: (_, {products}) => products.reduce((cur, pre) => cur + pre.orders.length, 0)
            },
            {
                title: 'Percent',
                dataIndex: 'percent',
                key: 'percent',
                render: (item, market, index) => {
                    const percent = round( market.risk / record.risk * 100, 2);
                    const config = {
                        height: 20,
                        width: 120,
                        autoFit: true,
                        percent: percent / 100,
                        color: ['#d51346', '#E8EDF3'],
                    };
                    return (
                        <Row>
                            <Col span={18}>
                                <Progress {...config} />
                            </Col>
                            <Col offset={1} span={5}>
                                {`${percent}%`}
                            </Col>
                        </Row>
                    );
                },
            },
        ];

        return <Table
            columns={columns}
            dataSource={record.markets}
            rowKey="market_id"
            pagination={false}
            expandable={{
                expandRowByClick: true,
                expandIconAsCell: true,
                expandedRowRender: (market) => expandedProductRowRender(market),
            }}
        />;
    };
  const columns = [
    {
      title: 'ID',
      width: "8%",
      ellipsis: true,
      dataIndex: 'event_id',
      key: 'event_id',
      render: (_, { event_id, createdAt, id}) => <Tooltip title={event_id} key={id} placement="bottom">{event_id}</Tooltip>,
    },
      {
          title: 'League',
          width: "8%",
          key: 'season_name',
          render: (_, { event_id, id}) => eventsDetail[event_id]?.season_name
      },
      {
          title: 'Country',
          width: "8%",
          key: 'country',
          render: (_, { event_id, id}) => eventsDetail[event_id]?.country
      },

    {
        title: 'PreMatch / InPlay',
        dataIndex: 'status',
        width: "10%",
        key: 'status',
        render: (_, { event_id, status, id}) =>
            eventsDetail[event_id] &&
            <Tag color={eventsDetail[event_id]?.status === 'started' ? 'green' : 'yellow'} key={id}>
                {eventsDetail[event_id]?.status.toUpperCase()}
            </Tag>
    },
    {
        title: 'Event Type',
        key: 'event_type',
        width: "7%",
        render: (_, { event_id, id}) => eventsDetail[event_id]?.event_type
    },
      {
          title: 'Event',
          key: 'event',
          width: "12%",
          ...getColumnSearchProps(
              'Event',
              eventSearch,
              eventSearchInput,
              setEventSearch,
              setEventSearchedColumn,
              eventSearchedColumn,
              eventFilter,
          ),
          render: (_, { event_id, id}) => eventsDetail[event_id]?.event_name
      },
      {
          title: 'Team A',
          key: 'team_a',
          width: "8%",
          render: (_, { event_id, id}) => eventsDetail[event_id]?.team_b?.name
      },
      {
          title: 'Team B',
          dataIndex: 'team_b',
          key: 'team_b',
          width: "8%",
          render: (_, { event_id, id}) => eventsDetail[event_id]?.team_b?.name
      },
      {
          title: 'Start Date',
          key: 'start_date',
          width: "7%",
          ellipsis: true,
          render: (_, { event_id, createdAt, id}) => {
              const dateTime = moment(eventsDetail[event_id]?.start_date)
              return <Tooltip title={dateTime.format('YYYY-MM-DD HH:mm:ss')} key={id} placement="bottom">
                  {dateTime.format('YYYY-MM-DD HH:mm:ss')}
              </Tooltip>
          },
      },
      {
          title: 'Expected Return',
          key: 'expected_return',
          sorter: (a, b) => b.return - a.return,
          render: (_, record) => formatCurrency(record.return)
      },
      {
          title: 'Potential Risk',
          key: 'potential_risk',
          sorter: (a, b) => b.risk - a.risk,
          render: (_, {risk}) => formatCurrency(risk)
      },
      {
          title: 'Amount',
          key: 'amount',
          sorter: (a, b) => b.amount - a.amount,
          render: (_, {amount}) => formatCurrency(amount)
      },
  ];

  return (
    <Card>
      <FetchDataBar {...barConfig}/>
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        rowKey="event_id"
        pagination={{
          defaultPageSize: 15,
        }}
        expandable={{
            expandRowByClick: true,
            expandIconAsCell: true,
            expandedRowRender: record => expandedRowRender(record),
            defaultExpandedRowKeys: ['0'],
        }}
      />
    </Card>
  );
}

export default Dashboard;
