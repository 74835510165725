/* eslint-disable comma-dangle */
import { useEffect, useState } from 'react';
import { encode as base64Encode } from 'base-64';

const useLogin = () => {
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [hostname, setHostname] = useState({});

  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    const targetHostname = window.location.hostname;
    const encoded = base64Encode(targetHostname);
    setHostname(encoded);
  }, []);

  return {
    loginEmail,
    setLoginEmail,
    loginPassword,
    setLoginPassword,
    hostname,
    onFinish,
    onFinishFailed
  };
};

export default useLogin;
