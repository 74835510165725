/* eslint-disable array-callback-return,no-console,guard-for-in,no-restricted-syntax,max-len,no-shadow,no-return-await,implicit-arrow-linebreak,no-param-reassign,prefer-const */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import moment from 'moment/moment';
import { message } from 'antd';
import {
  collection, getDocs, getFirestore, orderBy, query, Timestamp, where,
} from 'firebase/firestore';
import { each, groupBy } from 'lodash';
import { firestoreMTS } from '../utils/firestore-config-mts';
import { getEventsDetailApi } from '../Api/omsAPI';
import useCurrency from './useCurrency';

const db = getFirestore(firestoreMTS);
const LivePeriod = 5000; // 5000 millisecond
function useOMS() {
  const [data, setData] = useState([]);
  const [env, setEnv] = useState('dev');
  const [count, setCount] = useState(0);
  const [eventsDetail, setEventsDetail] = useState({});
  const [startAt, setStartAt] = useState(moment().add(-1, 'days'));
  const [endAt, setEndAt] = useState(moment());
  const [pageLimit, setPageLimit] = useState(2000);
  const [loading, setLoading] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [isRiskLive, setIsRiskLive] = useState(false);
  const [isRDLive, setIsRDLive] = useState(false);
  const liveTimer = useRef(null);
  const [cancelledCodeFilter, setCancelledCodeFilter] = useState([]);

  const { currencyRates } = useCurrency();

  const getDocDataArr = (querySnapshot, status = 'all') => {
    let docDataArr = [];
    let cancelledCodeFilArr = [];
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      if (status !== 'all' && docData.status !== status) return;
      docData.value = 1;
      cancelledCodeFilArr = [...cancelledCodeFilArr, doc.data().cancelledCode];
      docData.dateTime = moment(docData.createdAt?.toDate()).format('MM/DD HH:00');
      docDataArr = [...docDataArr, docData];
    });
    return { docDataArr, cancelledCodeFilArr };
  };

  const getCompletedDocDataArr = (querySnapshot) => {
    let docDataArr = [];
    let key = 0;
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      const tmp = docData;
      docData.ckexOrder.payload.forEach((item) => {
        tmp.key = key;
        tmp.ckexOrder.payload = item;
        docDataArr = [...docDataArr, tmp];
      });
      key += 1;
    });
    return docDataArr;
  };

  // eslint-disable-next-line no-shadow
  const getMtsCollection = (env) =>
  // eslint-disable-next-line no-nested-ternary,implicit-arrow-linebreak
    (env === 'dev'
      ? process.env.REACT_APP_GCP_FIRESTORE_COLLECTION_DEV
      : env === 'pre'
        ? process.env.REACT_APP_GCP_FIRESTORE_COLLECTION_PRE
        : process.env.REACT_APP_GCP_FIRESTORE_COLLECTION_PROD);

  const getAllData = async () =>
    await getDocs(
      query(
        collection(db, getMtsCollection(env)),
        where('createdAt', '>=', Timestamp.fromMillis(startAt.valueOf())),
        where('createdAt', '<=', Timestamp.fromMillis(endAt.valueOf())),
        orderBy('createdAt', 'desc'),
      ),
    );

  const getCompletedData = async () =>
    await getDocs(
      query(
        collection(db, getMtsCollection(env)),
        where('createdAt', '>=', Timestamp.fromMillis(startAt.valueOf())),
        where('createdAt', '<=', Timestamp.fromMillis(endAt.valueOf())),
        where('status', '==', 'completed'),
        orderBy('createdAt', 'desc'),
      ),
    );

  const getLiveData = async (from = moment().add(-5, 'minutes')) =>
    await getDocs(
      query(
        collection(db, getMtsCollection(env)),
        where('createdAt', '>=', Timestamp.fromMillis(from.valueOf())),
        orderBy('createdAt', 'desc'),
      ),
    );

  const getCompletedLiveData = async (from = moment().add(-5, 'minutes')) =>
    await getDocs(
      query(
        collection(db, getMtsCollection(env)),
        where('createdAt', '>=', Timestamp.fromMillis(from.valueOf())),
        where('status', '==', 'completed'),
        orderBy('createdAt', 'desc'),
      ),
    );

  const fetch = async (filters = { status: 'all' }, isLive = false) => {
    try {
      setLoading(true);
      const querySnapshot = isLive ? await getLiveData(moment().add(-5, 'minutes')) : await getAllData();
      const { docDataArr, cancelledCodeFilArr } = getDocDataArr(querySnapshot, filters.status);
      setCount(docDataArr.length);
      setData(docDataArr);
      setCancelledCodeFilter([...new Set(cancelledCodeFilArr)]);
      setLoading(false);
    } catch (e) {
      message.error(e);
      setLoading(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const getEventsDetailArr = async (docData) => {
    try {
      const eventIds = [];
      docData.forEach((order) => order.ckexOrder.payload.selections.forEach((selection) => eventIds.push(`${selection.event_id}/${selection.market_id}/${selection.product_id}`)));
      const res = await getEventsDetailApi(env, [...new Set(eventIds)]);
      return res.status === 200 ? res.data.data.events : [];
    } catch (e) {
      message.error(e);
      return [];
    }
  };

  const filterOpenEvents = (docData, eventDetail) =>
    docData.filter((order, index) => {
      docData[index].ckexOrder.payload.selections = order.ckexOrder.payload.selections.filter((selection) =>
        (eventDetail[selection.event_id]
          && eventDetail[selection.event_id].markets[selection.market_id]
          && eventDetail[selection.event_id].markets[selection.market_id].chloe_products[selection.product_id]));
      return docData[index].ckexOrder.payload.selections.length > 0;
    });

  const groupByEventsArr = (docDataArr) => {
    const e = [];
    let key = 1;
    each(groupBy(docDataArr, 'ckexOrder.payload.selections.0.event_id'), (events, eventId) => {
      const m = [];
      let eAmount = 0;
      let eReturn = 0;
      let eRisk = 0;
      each(groupBy(events, 'ckexOrder.payload.selections.0.market_id'), (markets, marketId) => {
        const p = [];
        let mAmount = 0;
        let mReturn = 0;
        let mRisk = 0;
        each(groupBy(markets, 'ckexOrder.payload.selections.0.product_id'), (products, productId) => {
          key += 1;
          p.push({
            key,
            orders: products.map((product) => {
              const pAmount = parseFloat(product.ckexOrder.payload.quantity) / parseFloat(currencyRates[product.ticket.Sender.Currency]);
              const pReturn = pAmount * parseFloat(product.ckexOrder.payload.selections[0].odds);
              const pRisk = pReturn - pAmount;
              mAmount += pAmount;
              mReturn += pReturn;
              mRisk += pRisk;
              return {
                quantity: product.ckexOrder.payload.quantity,
                currency: product.ticket.Sender.Currency,
                amount: pAmount,
                return: pReturn,
                risk: pRisk,
                key,
                ...product.ckexOrder.payload.selections[0],
              };
            }),
          });
        });
        eAmount += mAmount;
        eReturn += mReturn;
        eRisk += mRisk;
        m.push({
          market_id: marketId,
          amount: mAmount,
          return: mReturn,
          risk: mRisk,
          products: p,
        });
      });
      e.push({
        event_id: eventId,
        amount: eAmount,
        return: eReturn,
        risk: eRisk,
        markets: m,
      });
    });
    e.sort((a, b) => b.risk - a.risk);
    return e;
  };

  const fetchCompleted = async (islive = false, groupByEvents = false) => {
    try {
      setLoading(true);
      const querySnapshot = islive ? await getCompletedLiveData(moment().add(-5, 'minutes')) : await getCompletedData();
      let docDataArr = getCompletedDocDataArr(querySnapshot);
      const eDetail = await getEventsDetailArr(docDataArr);
      docDataArr = filterOpenEvents(docDataArr, eDetail);
      if (groupByEvents) {
        docDataArr = docDataArr.filter((order) => order.ckexOrder.payload.selections.length === 1);
        docDataArr = groupByEventsArr(docDataArr);
      }
      setEventsDetail(eDetail);
      setCount(docDataArr.length);
      setData(docDataArr);
      setLoading(false);
    } catch (e) {
      message.error(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isLive) return () => clearInterval(liveTimer.current);
    fetch({ status: 'all' }, true);
    liveTimer.current = setInterval(() => fetch({ status: 'all' }, true), LivePeriod);
    return () => clearInterval(liveTimer.current);
  }, [isLive]);

  useEffect(() => {
    if (!isRiskLive) return () => clearInterval(liveTimer.current);
    fetchCompleted(true);
    liveTimer.current = setInterval(() => fetchCompleted(true), LivePeriod);
    return () => clearInterval(liveTimer.current);
  }, [isRiskLive]);

  useEffect(() => {
    if (!isRDLive) return () => clearInterval(liveTimer.current);
    fetchCompleted(true, true);
    liveTimer.current = setInterval(() => fetchCompleted(true, true), LivePeriod);
    return () => clearInterval(liveTimer.current);
  }, [isRDLive]);

  return {
    isRiskLive,
    setIsRiskLive,
    isRDLive,
    setIsRDLive,
    isLive,
    setIsLive,
    loading,
    env,
    setLoading,
    setStartAt,
    setEndAt,
    startAt,
    endAt,
    data,
    setData,
    setEnv,
    count,
    setCount,
    cancelledCodeFilter,
    eventsDetail,
    fetch,
    fetchCompleted,
    getDocDataArr,
  };
}

export default useOMS;
