/* eslint-disable no-plusplus */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
import ExcelJs from 'exceljs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTrade } from '../../features/tradeSlice';

const useDownloadAllClick = () => {
  const getAllOrders = (trades) => {
    // 拉取所有 orders, 不分 trade, 集合成一個陣列
    let result = [];
    trades?.forEach((eachTrade) => {
      const copyOrder = eachTrade?.trade_detail?.map((order) => ({
        // trade_detail 中要新增 trade_id 欄位，在這之前，需要先做資料的複製，不能更改原資料
        ...order,
        trade_id: eachTrade?.trade_id
      }));
      result = result?.concat(copyOrder);
    });
    return result;
  };

  const getTradeRow = (perData) => {
    const targetArr = [];
    const tradeID = perData?.trade_id;
    const playerID = perData?.player_id;
    const eventID = perData?.event_id;
    const marketID = perData?.market_id;
    const userID = perData?.user_id;
    const event = perData?.event_name?.en;
    const market = perData?.market_name?.en;
    const tradeAmount = perData?.trade_amount;
    const validTrade = perData?.valid_trade;
    const { profit } = perData;
    const { commission } = perData;
    const result = perData?.outcome_name?.en;
    const updateTime = perData?.update_date;
    const settleTime = perData?.settle_order_dt;
    const orderCount = perData?.trade_detail?.length;

    targetArr.push(
      tradeID,
      playerID,
      eventID,
      marketID,
      userID,
      event,
      market,
      tradeAmount,
      validTrade,
      profit,
      commission,
      result,
      updateTime,
      settleTime,
      orderCount
    );
    return targetArr;
  };

  const getOrderRow = (perData) => {
    const targetArr = [];
    const tradeID = perData?.trade_id;
    const placeOrderID = perData?.place_order_id;
    const productID = perData?.product_id;
    const selection = perData?.product_name?.en;
    const layBack = perData?.layback;
    const line = perData?.line;
    const placePrice = perData?.place_order_price;
    const placetime = perData?.place_order_dt;
    const unmatchedSize = perData?.unmatched_order_quantity;
    const avgMatchedPrice = perData?.matched_order_price;
    const matchedSize = perData?.matched_order_quantity;
    const matchedTime = perData?.matched_order_dt;
    const cancelSize = perData?.cancel_order_quantity;
    const voidReason = perData?.settle_remark;

    targetArr.push(
      tradeID,
      placeOrderID,
      productID,
      selection,
      layBack,
      line,
      placePrice,
      placetime,
      unmatchedSize,
      avgMatchedPrice,
      matchedSize,
      matchedTime,
      cancelSize,
      voidReason
    );
    return targetArr;
  };

  const getTradeRows = (allData) => {
    const targetArr = [];
    for (let i = 0; i < allData?.length; i++) {
      targetArr.push(getTradeRow(allData[i]));
    }
    return targetArr;
  };

  const getOrderRows = (allData) => {
    const targetArr = [];
    for (let i = 0; i < allData?.length; i++) {
      targetArr.push(getOrderRow(allData[i]));
    }
    return targetArr;
  };

  const handleDownloadAll = (trades) => {
    const tradeWorkbook = new ExcelJs.Workbook();
    const tradeSheet = tradeWorkbook?.addWorksheet('singleTradeList');

    tradeSheet.addTable({
      name: 'singleTradeList',
      ref: 'A1', // 從 A1 開始
      columns: [
        { name: 'TradeID' },
        { name: 'PlayerID' },
        { name: 'EventID' },
        { name: 'MarketID' },
        { name: 'UserID' },
        { name: 'Event' },
        { name: 'Market' },
        { name: 'Trade amount' },
        { name: 'Valid trade' },
        { name: 'Profit' },
        { name: 'Commission' },
        { name: 'Result' },
        { name: 'Update time' },
        { name: 'Settle time' },
        { name: 'OrderCount' }
      ],
      rows: getTradeRows(trades)
    });

    tradeWorkbook.csv.writeBuffer().then((content) => {
      const link = document.createElement('a');
      const blobData = new Blob([content], {
        type: 'application/vnd.ms-excel;charset=utf-8;'
      });
      link.download = 'singleTradeList.csv';
      link.href = URL.createObjectURL(blobData);
      link.click();
    });
  };

  const handleDownloadAllOrders = (data) => {
    const orderWorkbook = new ExcelJs.Workbook();
    const orderSheet = orderWorkbook?.addWorksheet('singleOrderList');
    const orders = getAllOrders(data);

    orderSheet.addTable({
      name: 'singleOrderList',
      ref: 'A1', // 從 A1 開始
      columns: [
        { name: 'TradeID' },
        { name: 'PlaceOrderID' },
        { name: 'ProductID' },
        { name: 'Selection' },
        { name: 'LayBack' },
        { name: 'Line' },
        { name: 'PlacePrice' },
        { name: 'Placetime' },
        { name: 'UnmatchedSize' },
        { name: 'AvgMatchedPrice' },
        { name: 'MatchedSize' },
        { name: 'MatchedTime' },
        { name: 'CancelSize' },
        { name: 'VoidReason' }
      ],
      rows: getOrderRows(orders)
    });

    orderWorkbook.csv.writeBuffer().then((content) => {
      const link = document.createElement('a');
      const blobData = new Blob([content], {
        type: 'application/vnd.ms-excel;charset=utf-8;'
      });
      link.download = 'singleOrderList.csv';
      link.href = URL.createObjectURL(blobData);
      link.click();
    });
  };

  return {
    handleDownloadAll,
    handleDownloadAllOrders
  };
};

export default useDownloadAllClick;
