/* eslint-disable no-alert */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
import { Col, Row, Table, Form, Input, Button, message } from 'antd';
import React from 'react';
import useChangePassword from './useChangePassword';

function ChangePasswordPage() {
  const { onFinish, onFinishFailed, setNewPassword, handleNewPasswordClick } = useChangePassword();

  return (
    <div className="site-layout-content">
      <Row
        style={{
          justifyContent: 'left'
        }}
      >
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <div
            className="grid place-items-center"
            style={{
              minWidth: '360px',
              width: '100%',
              minHeight: '800px',
              height: '100%'
            }}
          >
            <Form
              name="basic"
              labelCol={{
                span: 8
              }}
              wrapperCol={{
                span: 8
              }}
              initialValues={{
                remember: true
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              style={{
                position: 'relative',
                top: '40%',
                paddingLeft: '100px'
              }}
            >
              <Form.Item
                label="New Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your new password!'
                  }
                ]}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={handleNewPasswordClick}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col
          span={4}
          offset={8}
          style={{
            marginTop: '20px'
          }}
        />
      </Row>
    </div>
  );
}

export default ChangePasswordPage;
