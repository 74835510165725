import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const getCurrencyRatesApi = (currency = 'USD') => axios.get(`https://open.er-api.com/v6/latest/${currency}`);

export const getEventsDetailApi = (env, empIds) => {
  const url = env === 'dev'
    ? `${process.env.REACT_APP_QUOTE_API_URL_DEV}matches-detail/search`
    : `${process.env.REACT_APP_QUOTE_API_URL_PROD}matches-detail/search`;
  const token = env === 'dev'
    ? `bearer ${process.env.REACT_APP_QUOTE_API_KEY_DEV}`
    : `bearer ${process.env.REACT_APP_QUOTE_API_KEY_PROD}`;
  return axios.post(url, { emp_ids: empIds }, { headers: { authorization: token } });
};
