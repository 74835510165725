/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable */
import { message } from 'antd';
import axios from 'axios';

let ACCESS_TOKEN = sessionStorage.getItem('accessToken');

// order 相關的 api
// BASE_ORDER_URL 是你 API 的主要 Domain，之後發請求時只要填相對路徑就可以了
const tradeRequest = axios.create({
  baseURL: process.env.REACT_APP_BASE_TRADE_URL,
  headers: {
    Authorization: `bearer ${ACCESS_TOKEN}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

export const interceptor = (store) => {
  // request interceptors
  tradeRequest.interceptors.request.use(
    function (config) {
      // do something before request is sent
      // 每次發 request 以前都會幫我檢查 sessionStorage 中的 token，避免第一次調用時 token 為 undefined
      const token = sessionStorage.getItem('accessToken');
      const auth = token ? `bearer ${token}` : '';
      config.headers.Authorization = auth;
      return config;
    },
    function (error) {
      // do something with request error
      return Promise.reject(error);
    }
  );

  // response interceptors
  tradeRequest.interceptors.response.use(
    function (response) {
      // do something with response data
      let isOrderData = response.data.count;
      return response;
    },
    function (error) {
      // do something with response error
      if (error.response) {
        switch (error.response.status) {
          case 400:
            message.error('非預期錯誤，請聯絡管理員');
            // go to login page
            break;
          case 401:
            message.error('憑證過期，請再登入一次');
            // go to login page
            window.location.href = '/';
            break;
          case 403:
            message.error('你沒有權限或已被禁止訪問，請聯絡網站管理員');
            sessionStorage.removeItem('access_token');
            // go to 403 page
            break;
          case 404:
            message.error('你要找的頁面不存在');
            // go to 404 page
            break;
          case 408:
            message.error('網路狀況不佳，請重新連網或換個網路連結');
            // go to 408 page
            break;
          case 500:
            message.error('程式發生問題');
            // go to 500 page
            break;
          default:
            message.error(error.message);
        }
      }
      if (!window.navigator.onLine) {
        message.error('網路出了點問題，請重新連線後重整網頁');
        return;
      }
      return Promise.reject(error);
    }
  );
};

// trades : get settle or open trades，直接調用
export const getAllTradesApi = (params) => {
  return tradeRequest.get(
    `list-ts-trades/?merchant_codes=${params.merchant}&market_type=${
      params.marketType
    }&re_settle=${params.resettled}${
      params.eventType ? `&event_type_ids=${params.eventType}` : ``
    }&place_order_ids=${params.orderID}&trade_ids=${
      params.tradeID
    }&market_ids=${params.marketID}&player_ids=${params.playerID}&user_ids=${
      params.userID
    }&order_status=${params.orderStatus}&update_date_dt_range_start=${
      params.formatedStartDate
    }&update_date_dt_range_end=${params.formatedEndDate}&event_ids=${
      params.eventID
    }&record_count=1000&page=${params.page}&key=${params.userInfo.apiKey}&sort_column=${
      params.sortColumn
    }&ascending=${params.sortBy === 'true' ? true : false}`
  );
};
