import styled from 'styled-components';

export const FormContent = styled.div`
  display: flex;
`;

export const InputForms = styled.div`
  display: flex;
  justify-content: space-around;
`;
