/* eslint-disable comma-dangle */
/* eslint-disable import/prefer-default-export */
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBgjo4eitslKrG6q97DahlWVfsCHZ8Cxt0',
  authDomain: 'ad-caesar-jfq2.firebaseapp.com',
  projectId: 'ad-caesar-jfq2',
  storageBucket: 'ad-caesar-jfq2.appspot.com',
  messagingSenderId: '527823302725',
  appId: '1:527823302725:web:0cd1223981390044a0332c'
};

// 啟動 oauth firebase app
const app = initializeApp(firebaseConfig, 'googleAuthApp');
export const authGoogle = getAuth(app);
