/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable comma-dangle */
import { Button, Table } from 'antd';
import React from 'react';
import useGetBonus from './useGetBonus';

function BonusForm() {
  const { dataSource, columns, onAddBonus } = useGetBonus();

  return (
    <>
      <Button>Expired</Button>
      <Button>Now</Button>
      <Button>Future</Button>
      <Button onClick={onAddBonus}>Add a new bonus</Button>
      <Table columns={columns} dataSource={dataSource} />
    </>
  );
}

export default BonusForm;
