import React from 'react';
import useGetParam from '../useGetParam';

function WinLossByMarketName() {
  const param = useGetParam();
  const encodedParam = param.param;

  return (
    <iframe
      title="win-loss-by-market-name"
      src={`https://datastudio.google.com/embed/reporting/958410df-9d39-4b4d-8400-50a9973487f6/page/p_wudukrfp1c?params=${encodedParam}&hl=en`}
      frameBorder="0"
      style={{ border: '0', height: '50vw', width: '90vw' }}
      allowFullScreen
    />
  );
}

export default WinLossByMarketName;
