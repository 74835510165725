/* eslint-disable camelcase */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-wrap-multilines */
import { onAuthStateChanged } from 'firebase/auth';
import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { setUserInfo } from '../features/userSlice';
import { authGoogle } from '../utils/firebase-config-google';
import { authMtbo } from '../utils/firebase-config-mtbo';
import useIdentity from './useIdentity';
import { ADMIN, MTBO, MTXX } from '../utils/constants';

const useAuth = () => {
  const { getIdentity } = useIdentity();
  const dispatch = useDispatch();

  // 即時偵測登入狀態改變
  useLayoutEffect(() => {
    const unsubscribe = onAuthStateChanged(authGoogle, (currentUser) => {
      if (getIdentity(currentUser?.accessToken) === ADMIN) {
        const { accessToken } = authGoogle.currentUser;
        const payload = {
          user: getIdentity(accessToken),
          accessToken,
          apiKey: authGoogle.config.apiKey,
          aud: jwt_decode(accessToken).aud
        };
        dispatch(setUserInfo(payload));
        sessionStorage.setItem('accessToken', accessToken);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useLayoutEffect(() => {
    const unsubscribe = onAuthStateChanged(authMtbo, (currentUser) => {
      if (getIdentity(currentUser?.accessToken) === MTBO) {
        const token = currentUser?.accessToken;
        const payload = {
          user: getIdentity(token),
          accessToken: token,
          apiKey: authMtbo?.config?.apiKey,
          aud: jwt_decode(token).aud
        };
        dispatch(setUserInfo(payload));
        sessionStorage.setItem('accesstoken', token);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useLayoutEffect(() => {
    const unsubscribe = onAuthStateChanged(authMtbo, (currentUser) => {
      if (getIdentity(currentUser?.accessToken) === MTXX) {
        const token = currentUser?.accessToken;
        const payload = {
          user: getIdentity(token),
          accessToken: token,
          apiKey: authMtbo?.config?.apiKey,
          aud: jwt_decode(token).aud
        };
        dispatch(setUserInfo(payload));
        sessionStorage.setItem('accesstoken', token);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
};

export default useAuth;
