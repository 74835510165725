/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useRef } from 'react';
import {
  Button, Input, message, Space,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

function useOrderList() {
  const [userIDSearch, setUserIDSearch] = useState('');
  const [userIDSearchedColumn, setUserIDSearchedColumn] = useState('');
  const userIDSearchInput = useRef(null);
  const [usernameSearch, setUsernameSearch] = useState('');
  const [usernameSearchedColumn, setUsernameSearchedColumn] = useState('');
  const usernameSearchInput = useRef(null);
  const [merchantCodeSearch, setMerchantCodeSearch] = useState('');
  const [merchantCodeSearchedColumn, setMerchantCodeSearchedColumn] = useState('');
  const merchantCodeSearchInput = useRef(null);
  const [eventSearch, setEventSearch] = useState('');
  const [eventSearchedColumn, setEventSearchedColumn] = useState('');
  const eventSearchInput = useRef(null);
  const [marketSearch, setMarketSearch] = useState('');
  const [marketSearchedColumn, setMarketSearchedColumn] = useState('');
  const marketSearchInput = useRef(null);
  const [productSearch, setProductSearch] = useState('');
  const [productSearchedColumn, setProductSearchedColumn] = useState('');
  const productSearchInput = useRef(null);

  // eslint-disable-next-line max-len,no-use-before-define
  const getMerchantCodeSearchProps = () => getColumnSearchProps(
    'merchantCode',
    merchantCodeSearch,
    merchantCodeSearchInput,
    setMerchantCodeSearch,
    setMerchantCodeSearchedColumn,
    merchantCodeSearchedColumn,
  );

  // eslint-disable-next-line max-len,no-use-before-define
  const getUsernameSearchProps = () => getColumnSearchProps(
    'username',
    usernameSearch,
    usernameSearchInput,
    setUsernameSearch,
    setUsernameSearchedColumn,
    usernameSearchedColumn,
  );

  const getColumnSearchProps = (
    dataIndex,
    searchText,
    searchInput,
    setSearchText,
    setSearchedColumn,
    searchedColumn,
    customFilterFunc = null,
  ) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => {
            confirm();
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
              setUserIDSearch(selectedKeys[0]);
              setUserIDSearchedColumn(dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                clearFilters();
                setSearchText('');
              }
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    // eslint-disable-next-line max-len
    onFilter: customFilterFunc != null ? customFilterFunc : (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  return {
    eventSearch,
    eventSearchInput,
    setEventSearch,
    setEventSearchedColumn,
    eventSearchedColumn,
    marketSearch,
    marketSearchInput,
    setMarketSearch,
    setMarketSearchedColumn,
    marketSearchedColumn,
    productSearch,
    productSearchInput,
    setProductSearch,
    setProductSearchedColumn,
    productSearchedColumn,
    getUsernameSearchProps,
    getMerchantCodeSearchProps,
    getColumnSearchProps,
  };
}

export default useOrderList;
