const policyInfos = [
  {
    project: process.env.REACT_APP_GCP_ARMOR_PROJECT_ID_CKEX,
    securityPolicy: process.env.REACT_APP_GCP_ARMOR_SECURITY_POLICY_CKEX,
    priority: process.env.REACT_APP_GCP_ARMOR_PRIORITY_CKEX,
  },
  {
    project: process.env.REACT_APP_GCP_ARMOR_PROJECT_ID_MERCHANT,
    securityPolicy: process.env.REACT_APP_GCP_ARMOR_SECURITY_POLICY_MERCHANT,
    priority: process.env.REACT_APP_GCP_ARMOR_PRIORITY_MERCHANT,
  },
  {
    project: process.env.REACT_APP_GCP_ARMOR_PROJECT_ID_CAESAR,
    securityPolicy: process.env.REACT_APP_GCP_ARMOR_SECURITY_POLICY_CAESAR,
    priority: process.env.REACT_APP_GCP_ARMOR_PRIORITY_CAESAR,
  },
];

export default policyInfos;
