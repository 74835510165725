/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Transfer from '../../components/Whitelist/Transfer/Transfer';
import useWhitelist from './useWhitelist';
import InputBlock from '../../components/Whitelist/InputBlock/InputBlock';
import { selectUserInfo } from '../../features/userSlice';
import { ADMIN } from '../../utils/constants';
// import useToken from '../../hooks/useToken';

function Whitelist() {
  const navigate = useNavigate();

  const {
    updateWhitelist,
    addWhiteIP,
    inputStatus,
    errorText,
    loading,
    inputIP,
    setInputIP,
    targetKeys,
    setTargetKeys,
    whitelist
  } = useWhitelist();

  const userInfo = useSelector(selectUserInfo);

  useLayoutEffect(() => {
    const { user } = userInfo;
    if (user !== ADMIN) navigate('/');
  }, []);

  return (
    <div className="site-layout-content">
      <Row
        style={{
          justifyContent: 'center'
        }}
      >
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <div className="grid place-items-center">
            <Row
              style={{
                justifyContent: 'center'
              }}
            >
              <InputBlock
                inputStatus={inputStatus}
                inputIP={inputIP}
                addWhiteIP={addWhiteIP}
                setInputIP={setInputIP}
                errorText={errorText}
              />
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <div className="grid place-items-center">
                  <Transfer
                    whitelist={whitelist}
                    targetKeys={targetKeys}
                    setTargetKeys={setTargetKeys}
                    loading={loading}
                  />
                </div>
              </Col>
              <Col
                span={4}
                offset={8}
                style={{
                  marginTop: '20px'
                }}
              >
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => updateWhitelist()}
                >
                  Update
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col
          span={4}
          offset={8}
          style={{
            marginTop: '20px'
          }}
        />
      </Row>
    </div>
  );
}

export default Whitelist;
