/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
import { Col, Layout, Row } from 'antd';
import React from 'react';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import FooterBar from '../../components/FooterBar/FooterBar';
import SideBar from '../../components/SideBar/SideBar';
import StyledDashboard from './style';

const { Content } = Layout;

function Dashboard({ children }) {
  return (
    <StyledDashboard>
      <Layout
        style={{
          minHeight: '100vh'
        }}
      >
        <SideBar />
        <Layout className="site-layout">
          <Content
            style={{
              padding: '0 40px'
            }}
          >
            <BreadCrumb />
            {children}
          </Content>
          <FooterBar />
        </Layout>
      </Layout>
    </StyledDashboard>
  );
}

export default Dashboard;
