/* eslint-disable no-underscore-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
import React, { useState } from 'react';
import { Input, Form, Button, Select, DatePicker } from 'antd';
import { FormContent, InputForms } from './style';

function BonusFilter() {
  const [marketType, setMarketType] = useState({});
  const [league, setLeague] = useState({});
  const [bonusName, setBonusName] = useState('');
  const [multiple, setMultiple] = useState('');
  const [amount, setAmount] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleAddBonusClick = () => {};
  const handleResetClick = () => {};

  return (
    <InputForms>
      <Form
        name="basic"
        labelCol={{
          span: 8
        }}
        wrapperCol={{
          span: 16
        }}
        initialValues={{
          remember: true
        }}
        autoComplete="off"
      >
        <FormContent>
          <div style={{ marginRight: '100px' }}>
            <Form.Item
              label="Merchant"
              name="merchant"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your merchant!'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Market type"
              name="market type"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please select your Market type!'
                }
              ]}
            >
              <Select
                defaultValue="Full-time-result"
                onChange={(e) => {
                  setMarketType(e);
                }}
              >
                <Select.Option value="MATCH_ODDS">
                  Full-time-result
                </Select.Option>
                <Select.Option value="MATCH_ODDS_NC">BookMaker</Select.Option>
                <Select.Option value="TOTAL_FANCY">Fancy</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="League"
              name="league"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please select your League!'
                }
              ]}
            >
              <Select
                defaultValue="Full-time-result"
                onChange={(e) => {
                  setLeague(e);
                }}
              >
                <Select.Option value="MATCH_ODDS">
                  Full-time-result
                </Select.Option>
                <Select.Option value="MATCH_ODDS_NC">BookMaker</Select.Option>
                <Select.Option value="TOTAL_FANCY">Fancy</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div style={{ marginRight: '100px' }}>
            <Form.Item
              label="Bonus name"
              name="bonus name"
              style={{ width: '120%' }}
              onChange={(e) => {
                setBonusName(e.target.value);
              }}
              rules={[
                {
                  required: false,
                  message: 'Please input your Bonus name!'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Multiple"
              name="multiple"
              style={{ width: '120%' }}
              onChange={(e) => {
                setMultiple(e.target.value);
              }}
              rules={[
                {
                  required: false,
                  message: 'Please input your Multiple!'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Amount"
              name="amount"
              style={{ width: '120%' }}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              rules={[
                {
                  required: false,
                  message: 'Please input your Amount!'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Start Date"
              name="start date"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your Start Date!'
                }
              ]}
            >
              <DatePicker
                onChange={(date) => {
                  setStartDate(date?._d);
                }}
              />
            </Form.Item>
            <Form.Item
              label="End Date"
              name="end date"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your End Date!'
                }
              ]}
            >
              <DatePicker
                onChange={(date) => {
                  setEndDate(date?._d);
                }}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{ offset: 8, span: 16 }}
              style={{ width: '120%' }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: '10px' }}
                onClick={handleResetClick}
              >
                Reset
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleAddBonusClick}
              >
                Add
              </Button>
            </Form.Item>
          </div>
        </FormContent>
      </Form>
    </InputForms>
  );
}

export default BonusFilter;
