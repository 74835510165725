/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
import { Layout } from 'antd';
import React from 'react';

const { Footer } = Layout;

function FooterBar() {
  return (
    <Footer
      style={{
        textAlign: 'center'
      }}
    >
      CKEX ADMIN ©2022 Created by CKEX
    </Footer>
  );
}

export default FooterBar;
