/* eslint-disable max-len */
import React from 'react';
import { each, groupBy, round } from 'lodash';
import { Column, DualAxes } from '@ant-design/charts';
import useCurrency from '../../../../hooks/useCurrency';

function OrderChart({ data, getMtsRate }) {
  const { currencyRates } = useCurrency();
  const chartData = [];
  data.forEach((item) => {
    const currency = item.ticket?.Sender?.Currency;
    const currencyRate = currencyRates[currency];
    if (!currency || !currencyRate) return;
    const amount = item.ckexOrder.payload.reduce((pre, curr) => pre + parseFloat(curr.quantity), 0) / currencyRate;
    chartData.push({
      ...item,
      amount,
    });
  });
  const lineData = [];
  each(groupBy(chartData, 'dateTime'), (items, k) => {
    const amount = items.reduce((pre, curr) => (curr.status === 'completed' ? pre + curr.amount : pre), 0);
    lineData.push({
      dateTime: k,
      amount: round(amount, 2),
    });
  });
  const config = {
    data: [data, lineData],
    xField: 'dateTime',
    yField: ['value', 'amount'],
    geometryOptions: [
      {
        geometry: 'column',
        isStack: true,
        seriesField: 'status',
        columnWidthRatio: 0.4,
        label: {
          position: 'middle',
          layout: [
            {
              type: 'interval-adjust-position',
            },
            {
              type: 'interval-hide-overlap',
            },
            {
              type: 'adjust-color',
            },
          ],
        },
      },
      {
        geometry: 'line',
      },
    ],
    interactions: [
      {
        type: 'element-highlight',
      },
      {
        type: 'active-region',
      },
    ],
    animation: true,
  };
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DualAxes {...config} />;
}

export default OrderChart;
