/* eslint-disable camelcase */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable comma-dangle */
import { Table } from 'antd';
import React from 'react';
import OrderForm from './OrderForm/OrderForm';
import useGetTrade from './useGetTrade';
import { StyledTradeForm } from './style';

function TradeForm(props) {
  const { tradeLoading } = props;
  const { columns, data, onChange } = useGetTrade();

  return (
    <StyledTradeForm>
      <Table
        className="yy"
        style={{ overflow: 'scroll' }}
        columns={columns}
        dataSource={data}
        onChange={onChange}
        pagination={false}
        expandRowByClick
        loading={tradeLoading}
        scrollToFirstRowOnChange={true}
        scroll={{
          x: 1500,
          y: 700
        }}
        expandable={{
          expandedRowRender: (record) => <OrderForm trade={record} />,
          rowExpandable: (record) => record.expandable
        }}
      />
    </StyledTradeForm>
  );
}

export default TradeForm;
