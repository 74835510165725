/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Input, Form, Button, Select, DatePicker, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FormContent, InputForms } from './style';
import { selectUserInfo } from '../../features/userSlice';
import { getTradesAsync, selectTrade } from '../../features/tradeSlice';
import formatDate from '../../utils/formatDate';
import useDownloadAllClick from '../../pages/ExportFilePage/useDownloadAllClick';
import useDownloadSingleClick from '../../pages/ExportFilePage/useDownloadSingleClick';

function TradeFilter(props) {
  const { setTradeLoading, setIsDataDownLoading } = props;
  const { trade } = useSelector(selectTrade);
  const totalCount = trade?.count;
  const pageParamLimition = Math.ceil(totalCount / 1000);

  let ACCESS_TOKEN = sessionStorage.getItem('accessToken');

  const { handleDownloadAll, handleDownloadAllOrders } = useDownloadAllClick();
  const { handleDownloadSingleClick } = useDownloadSingleClick();

  const [merchant, setMerchant] = useState('');
  const [playerID, setPlayerID] = useState('');
  const [userID, setUserID] = useState('');
  const [tradeID, setTradeID] = useState('');
  const [marketID, setMarketID] = useState('');
  const [orderID, setOrderID] = useState('');
  const [eventID, setEventID] = useState('');
  const [marketType, setMarketType] = useState('');
  const [eventType, setEventType] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [resettled, setResettled] = useState('');
  const [sortColumn, setSortColumn] = useState('profit');
  const [sortBy, setSortBy] = useState('true');

  const userInfo = useSelector(selectUserInfo);
  const { user } = userInfo;

  const dispatch = useDispatch();

  const handleSubmitClick = () => {
    let formatedStartDate = '';
    let formatedEndDate = '';
    if (startDate) formatedStartDate = formatDate(startDate);
    if (endDate) formatedEndDate = formatDate(endDate);
    const page = 1;

    const inputParams = {
      merchant,
      playerID,
      userID,
      tradeID,
      marketID,
      orderID,
      eventID,
      marketType,
      eventType,
      orderStatus,
      formatedStartDate,
      formatedEndDate,
      resettled,
      sortColumn,
      sortBy,
      user,
      userInfo,
      setTradeLoading,
      page
    };
    dispatch(getTradesAsync(inputParams));
  };

  const getPagination = (page, pageSize) => {
    let formatedStartDate = '';
    let formatedEndDate = '';
    if (startDate) formatedStartDate = formatDate(startDate);
    if (endDate) formatedEndDate = formatDate(endDate);

    const inputParams = {
      merchant,
      playerID,
      userID,
      tradeID,
      marketID,
      orderID,
      eventID,
      marketType,
      eventType,
      orderStatus,
      formatedStartDate,
      formatedEndDate,
      resettled,
      sortColumn,
      sortBy,
      user,
      userInfo,
      setTradeLoading,
      page
    };
    dispatch(getTradesAsync(inputParams));
  };

  const handleDownloadAllClick = () => {
    let formatedStartDate = '';
    let formatedEndDate = '';
    if (startDate) formatedStartDate = formatDate(startDate);
    if (endDate) formatedEndDate = formatDate(endDate);

    setIsDataDownLoading(true);

    let i = 1;
    const timeId = setInterval(() => {
      axios
        .get(
          `https://api.aecricex.xyz/api/order/merchant/list-ts-trades/?merchant_codes=${merchant}&market_type=${marketType}&re_settle=${resettled}${
            eventType ? `&event_type_ids=${eventType}` : ``
          }&place_order_ids=${orderID}&trade_ids=${tradeID}&market_ids=${marketID}&player_ids=${playerID}&user_ids=${userID}&order_status=${orderStatus}&update_date_dt_range_start=${formatedStartDate}&update_date_dt_range_end=${formatedEndDate}&event_ids=${eventID}&record_count=1000&page=${i}&key=${
            userInfo.apiKey
          }&sort_column=${sortColumn}&ascending=${
            sortBy === 'true' ? true : false
          }`,
          {
            headers: {
              Authorization: `bearer ${ACCESS_TOKEN}`,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        .then((response) => {
          const payload = response?.data?.result;
          handleDownloadAll(payload);
          handleDownloadAllOrders(payload);
          i++;
          if (i > pageParamLimition) {
            console.log('clearing');
            setIsDataDownLoading(false);
            clearInterval(timeId);
          }
        })
        .catch((error) => console.log(error));
    }, 4000);
  };

  return (
    <InputForms>
      <Form
        style={{ overflow: 'scroll', width: '75vw' }}
        name="basic"
        labelCol={{
          span: 8
        }}
        wrapperCol={{
          span: 16
        }}
        initialValues={{
          remember: true
        }}
        autoComplete="off"
      >
        <FormContent>
          <div style={{ marginRight: '100px' }}>
            <Form.Item
              label="Merchant"
              name="merchant"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your merchant!'
                }
              ]}
            >
              <Input
                onChange={(e) => {
                  setMerchant(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Trade ID"
              name="trade ID"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your Trade ID!'
                }
              ]}
            >
              <Input
                onChange={(e) => {
                  setTradeID(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Event ID"
              name="event ID"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your Event ID!'
                }
              ]}
            >
              <Input
                onChange={(e) => {
                  setEventID(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Market ID"
              name="market ID"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your Market ID!'
                }
              ]}
            >
              <Input
                onChange={(e) => {
                  setMarketID(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Order ID"
              name="order ID"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your Order ID!'
                }
              ]}
            >
              <Input
                onChange={(e) => {
                  setOrderID(e.target.value);
                }}
              />
            </Form.Item>
          </div>
          <div style={{ marginRight: '100px' }}>
            <Form.Item
              label="Player ID"
              name="player ID"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your Player ID!'
                }
              ]}
            >
              <Input
                onChange={(e) => {
                  setPlayerID(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label="User ID"
              name="user ID"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your User ID!'
                }
              ]}
            >
              <Input
                onChange={(e) => {
                  setUserID(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Market type"
              name="market type"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please select your Market type!'
                }
              ]}
            >
              <Select
                defaultValue=""
                onChange={(e) => {
                  setMarketType(e);
                }}
              >
                <Select.Option value="">All</Select.Option>
                <Select.Option value="MATCH_ODDS">
                  Full-time-result
                </Select.Option>
                <Select.Option value="MATCH_ODDS_NC">BookMaker</Select.Option>
                <Select.Option value="TOTAL_FANCY">Fancy</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Event type"
              name="event type"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your Event type!'
                }
              ]}
            >
              <Select
                defaultValue=""
                onChange={(e) => {
                  setEventType(e);
                }}
              >
                <Select.Option value="">All</Select.Option>
                <Select.Option value="1">Soccer</Select.Option>
                <Select.Option value="sr:sport:23">Volleyball</Select.Option>
                <Select.Option value="2">Tennis</Select.Option>
                <Select.Option value="4">Cricket</Select.Option>
                <Select.Option value="sr:sport:13">Aussie Rules</Select.Option>
                <Select.Option value="sr:sport:31">Badminton</Select.Option>
                <Select.Option value="sr:sport:3">Baseball</Select.Option>
                <Select.Option value="sr:sport:2">Basketball</Select.Option>
                <Select.Option value="sr:sport:17">Cycling</Select.Option>
                <Select.Option value="sr:sport:22">Darts</Select.Option>
                <Select.Option value="sr:sport:107">eSport</Select.Option>
                <Select.Option value="sr:sport:16">NFL</Select.Option>
                <Select.Option value="sr:sport:40">Formula 1</Select.Option>
                <Select.Option value="sr:sport:9">Golf</Select.Option>
                <Select.Option value="sr:sport:58">Greyhound</Select.Option>
                <Select.Option value="sr:sport:55">Horse racing</Select.Option>
                <Select.Option value="sr:sport:4">Ice Hockey</Select.Option>
                <Select.Option value="sr:sport:67">MotoGP</Select.Option>
                <Select.Option value="sr:sport:19">Snooker</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Order status"
              name="trade status"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your Order status!'
                }
              ]}
            >
              <Select
                defaultValue=""
                onChange={(e) => {
                  setOrderStatus(e);
                }}
              >
                <Select.Option value="">All</Select.Option>
                <Select.Option value="OPEN">OPEN</Select.Option>
                <Select.Option value="VOID">VOID</Select.Option>
                <Select.Option value="SETTLE">SETTLE</Select.Option>
                <Select.Option value="CANCEL">CANCEL</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Start Date"
              name="start date"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your Start Date!'
                }
              ]}
            >
              <DatePicker
                showTime
                onChange={(date) => {
                  setStartDate(date?._d);
                }}
              />
            </Form.Item>
            <Form.Item
              label="End Date"
              name="end date"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your End Date!'
                }
              ]}
            >
              <DatePicker
                showTime
                // defaultValue=
                onChange={(date) => {
                  setEndDate(date?._d);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Resettled"
              name="resettled"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your Resettled!'
                }
              ]}
            >
              <Select
                defaultValue=""
                onChange={(e) => {
                  setResettled(e);
                }}
              >
                <Select.Option value="">All</Select.Option>
                <Select.Option value="true">True</Select.Option>
                <Select.Option value="false">False</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Sort Column"
              name="sort column"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your Sort Column!'
                }
              ]}
            >
              <Select
                defaultValue="Profit"
                onChange={(e) => {
                  setSortColumn(e);
                }}
              >
                <Select.Option value="trade_amount">Trade amount</Select.Option>
                <Select.Option value="valid_trade">Valid trade</Select.Option>
                <Select.Option value="profit">Profit</Select.Option>
                <Select.Option value="update_date">Update time</Select.Option>
                <Select.Option value="settle_order_dt">
                  Settle time
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Sort By"
              name="sort by"
              style={{ width: '120%' }}
              rules={[
                {
                  required: false,
                  message: 'Please input your Sort By!'
                }
              ]}
            >
              <Select
                defaultValue="Ascending"
                onChange={(e) => {
                  setSortBy(e);
                }}
              >
                <Select.Option value="true">Ascending</Select.Option>
                <Select.Option value="false">Descending</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              wrapperCol={{ offset: 8, span: 16 }}
              style={{ width: '120%' }}
            >
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleSubmitClick}
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </FormContent>
        <Pagination
          defaultCurrent={1}
          defaultPageSize={1000}
          pageSize={1000}
          pageSizeOptions={[1000]}
          total={totalCount}
          onChange={getPagination}
        />
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginTop: '30px' }}
          onClick={handleDownloadSingleClick}
        >
          Download single page data
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginTop: '10px', marginLeft: '10px' }}
          onClick={handleDownloadAllClick}
        >
          Download all data
        </Button>
      </Form>
    </InputForms>
  );
}

export default TradeFilter;
