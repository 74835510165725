/* eslint-disable comma-dangle */
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../features/userSlice';

const useConstants = () => {
  const userInfo = useSelector(selectUserInfo);
  const SETTLE_TRADE_LIST_MANY_IFRAME_URL = `https://dash1554.aecricex.xyz/app/ae-cric-ex-backoffice/settled-trades-62c3ddef6a06384f70c5fb98//?access_token=${userInfo?.accessToken}&api=list-settle-trades-many&key=${userInfo.apiKey}&embed=true`;
  const SETTLE_TRADE_LIST_IFRAME_URL = `https://dash1554.aecricex.xyz/app/ae-cric-ex-backoffice/settled-trades-62c3ddef6a06384f70c5fb98//?access_token=${userInfo?.accessToken}&api=list-settle-trades&key=${userInfo.apiKey}&embed=true`;
  const OPEN_TRADE_LIST_MANY_IFRAME_URL = `https://dash1554.aecricex.xyz/app/ae-cric-ex-backoffice/open-trades-62c304ca6a06384f70c5fb70//?access_token=${userInfo?.accessToken}&api=list-open-trades-many&key=${userInfo.apiKey}&embed=true`;
  const OPEN_TRADE_LIST_IFRAME_URL = `https://dash1554.aecricex.xyz/app/ae-cric-ex-backoffice/open-trades-62c304ca6a06384f70c5fb70//?access_token=${userInfo?.accessToken}&api=list-open-trades&key=${userInfo.apiKey}&embed=true`;
  const FUND_LOG_IFRAME_URL = `https://dash1554.aecricex.xyz/app/ae-cric-ex-backoffice/fund-log-62c3eca16a06384f70c5fba6/?access_token=${userInfo?.accessToken}&embed=true`;

  return {
    SETTLE_TRADE_LIST_MANY_IFRAME_URL,
    SETTLE_TRADE_LIST_IFRAME_URL,
    OPEN_TRADE_LIST_MANY_IFRAME_URL,
    OPEN_TRADE_LIST_IFRAME_URL,
    FUND_LOG_IFRAME_URL
  };
};

export default useConstants;
