/* eslint-disable comma-dangle */
/* eslint-disable import/prefer-default-export */
/* eslint no-trailing-spaces: "error" */
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = JSON.parse(sessionStorage.getItem('firebaseConfig'));

// init mtboAuthApp firebase app
const app = initializeApp(firebaseConfig, 'mtboAuthApp');

export const authMtbo = getAuth(app);
