/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {Card, Table, Tag, Tooltip} from 'antd';
import useOrderList from "./useOrderList";
import moment from "moment/moment";
import useOMS from "../../../hooks/useOMS";
import useCurrency from "../../../hooks/useCurrency";
import FetchDataBar from "../../../components/FetchDataBar/FetchDataBar";

function OrderList() {
  const {
    currencyRates,
    formatCurrency,
  } = useCurrency()
  const {
    eventSearch,
    eventSearchInput,
    setEventSearch,
    setEventSearchedColumn,
    eventSearchedColumn,
    marketSearch,
    marketSearchInput,
    setMarketSearch,
    setMarketSearchedColumn,
    marketSearchedColumn,
    productSearch,
    productSearchInput,
    setProductSearch,
    setProductSearchedColumn,
    productSearchedColumn,
    getUsernameSearchProps,
    getMerchantCodeSearchProps,
    getColumnSearchProps,
  } = useOrderList()
  const {
    data,
    setEnv,
    loading,
    setStartAt,
    setEndAt,
    startAt,
    endAt,
    isRiskLive,
    setIsRiskLive,
    count,
    setCount,
    eventsDetail,
    fetchCompleted,
  } = useOMS()

  useEffect(() => {
    fetchCompleted();
  }, []);

  let barConfig = {
    setEnv,
    setStartAt,
    setEndAt,
    startAt,
    endAt,
    count,
    isLive: isRiskLive,
    setIsLive: setIsRiskLive,
    fetch: () => fetchCompleted(),
  }
  const eventFilter = (value, record) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const bet of record.ckexOrder.payload.selections) {
      // eslint-disable-next-line max-len
      if (eventsDetail[bet.event_id].event_name.toString().toLowerCase().includes(value.toLowerCase())) return true;
    }
    return false;
  };

  const marketFilter = (value, record) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const bet of record.ckexOrder.payload.selections) {
      // eslint-disable-next-line max-len
      if (eventsDetail[bet.event_id].markets[bet.market_id].market_name.toString().toLowerCase().includes(value.toLowerCase())) return true;
    }
    return false;
  };

  const productFilter = (value, record) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const bet of record.ckexOrder.payload.selections) {
      // eslint-disable-next-line max-len
      if (eventsDetail[bet.event_id].markets[bet.market_id].chloe_products[bet.product_id].product_name.toString().toLowerCase().includes(value.toLowerCase())) return true;
    }
    return false;
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'Id',
      fixed: 'left',
      width: "5%",
      ellipsis: true,
      visible: true,
      render: (_, record) => (
          <Tooltip title={record.id} key={record.id} placement="bottom">
            {record.id}
          </Tooltip>),
    },
    {
      title: 'Merchant Code',
      key: 'merchantCode',
      fixed: 'left',
      width: "5%",
      ellipsis: true,
      ...getMerchantCodeSearchProps(),
      render: (_, record) => (
          <Tooltip title={record.merchantCode} key={record.merchantCode} placement="bottom">
            {record.merchantCode}
          </Tooltip>),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      width: "5%",
      fixed: 'left',
      textWrap: 'word-break',
      ellipsis: true,
      ...getUsernameSearchProps(),
      render: (_, record) => (
          <Tooltip title={record.username} key={record.id} placement="bottom">
            <a >{record.username}</a>
          </Tooltip>),
    },
    {
      title: 'League',
      key: 'season_name',
      render: (_, {ckexOrder, id}) => eventsDetail[ckexOrder.payload.selections[0].event_id]?.season_name
    },
    {
      title: 'Country',
      key: 'country',
      render: (_, {ckexOrder, id}) => eventsDetail[ckexOrder.payload.selections[0].event_id]?.country
    },
    {
      title: 'PreMatch / InPlay',
      width: 120,
      key: 'status',
      render: (_, {ckexOrder, status, id}) =>
          eventsDetail[ckexOrder.payload.selections[0].event_id] &&
          <Tag color={eventsDetail[ckexOrder.payload.selections[0].event_id]?.status === 'started' ? 'green' : 'yellow'} key={id}>
        {eventsDetail[ckexOrder.payload.selections[0].event_id]?.status.toUpperCase()}
      </Tag>
    },
    {
      title: 'Event Type',
      key: 'event_type',
      render: (_, {ckexOrder, id}) => eventsDetail[ckexOrder.payload.selections[0].event_id]?.event_type
    },
    {
      title: 'Team A',
      key: 'team_a',
      render: (_, {ckexOrder, id}) => eventsDetail[ckexOrder.payload.selections[0].event_id]?.team_a?.name
    },
    {
      title: 'Team B',
      key: 'team_b',
      render: (_, {ckexOrder, id}) => eventsDetail[ckexOrder.payload.selections[0].event_id]?.team_b?.name
    },
    {
      title: 'Start Date',
      key: 'start_date',
      ellipsis: true,
      render: (_, {ckexOrder, createdAt, id}) => {
        const dateTime = moment(eventsDetail[ckexOrder.payload.selections[0].event_id]?.start_date)
        return <Tooltip title={dateTime.format('YYYY-MM-DD HH:mm:ss')} key={id} placement="bottom">
          {dateTime.format('YYYY-MM-DD HH:mm:ss')}
        </Tooltip>
      },
    },
    {
      title: 'Event',
      key: 'event',
      ...getColumnSearchProps(
          'Event',
          eventSearch,
          eventSearchInput,
          setEventSearch,
          setEventSearchedColumn,
          eventSearchedColumn,
          eventFilter,
      ),
      render: (_, {ckexOrder, id}) => eventsDetail[ckexOrder.payload.selections[0].event_id]?.event_name
    },
    {
      title: 'Market',
      key: 'market',
      ...getColumnSearchProps(
          'Market',
          marketSearch,
          marketSearchInput,
          setMarketSearch,
          setMarketSearchedColumn,
          marketSearchedColumn,
          marketFilter,
      ),
      render: (_, {ckexOrder, id}) => eventsDetail[ckexOrder.payload.selections[0].event_id]?.markets[ckexOrder.payload.selections[0].market_id]?.market_name,
    },
    {
      title: 'Product',
      key: 'product',
      ...getColumnSearchProps(
          'Product',
          productSearch,
          productSearchInput,
          setProductSearch,
          setProductSearchedColumn,
          productSearchedColumn,
          productFilter,
      ),
      render: (_, {ckexOrder, id}) => eventsDetail[ckexOrder.payload.selections[0].event_id]?.markets[ckexOrder.payload.selections[0].market_id]?.chloe_products[ckexOrder.payload.selections[0].product_id]?.product_name,
    },
    {
      title: 'Currency',
      key: 'currency',
      width: 95,
      render: (_, {ticket, id}) => ticket.Sender?.Currency
    },
    {
      title: 'Odds',
      key: 'odds',
      fixed: 'right',
      width: "3%",
      sorter: (a, b) => parseFloat(a.ckexOrder.payload.selections[0].odds) - parseFloat(b.ckexOrder.payload.selections[0].odds),
      render: (_, {ckexOrder, id}) => ckexOrder.payload.selections.reduce((pre, curr, idx) => pre + (parseInt(idx) === 0 ? '' : ' / ') + `${curr.odds}`, "")
    },
    {
      title: 'Stake',
      key: 'stake',
      fixed: 'right',
      width: "4%",
      sorter: (a, b) => parseFloat(a.ckexOrder.payload.quantity) - parseFloat(b.ckexOrder.payload.quantity),
      render: (_, {ckexOrder, id}) => ckexOrder.payload.quantity,
    },
    {
      title: 'Expected Return',
      key: 'expected_return',
      fixed: 'right',
      width: "5%",
      sorter: (a, b) => {
        const currencyRateA = currencyRates[a.ticket.Sender?.Currency];
        const currencyRateB = currencyRates[b.ticket.Sender?.Currency];
        const expectedReturnA = a.ckexOrder.payload.quantity * a.ckexOrder.payload.selections.reduce((p, c) => p * c.odds,1)
        const expectedReturnB = b.ckexOrder.payload.quantity * b.ckexOrder.payload.selections.reduce((p, c) => p * c.odds,1)
        return expectedReturnA / currencyRateA - expectedReturnB / currencyRateB
      },
      render: (_, {ckexOrder, ticket, id}) => {
        if (!ticket.Sender || !ticket.Bets || !ckexOrder) return '';
        const currency = ticket.Sender?.Currency;
        const currencyRate = currencyRates[currency];
        const expectedReturn = ckexOrder.payload.quantity * ckexOrder.payload.selections.reduce((p, c) => p * c.odds,1)
        return formatCurrency(expectedReturn / currencyRate)
      }
    },
    {
      title: 'Potential Risk',
      key: 'potential_risk',
      fixed: 'right',
      width: "5%",
      sorter: (a, b) => {
        const currencyRateA = currencyRates[a.ticket.Sender?.Currency];
        const currencyRateB = currencyRates[b.ticket.Sender?.Currency];
        const expectedReturnA = a.ckexOrder.payload.quantity * a.ckexOrder.payload.selections.reduce((p, c) => p * c.odds,1)
        const expectedReturnB = b.ckexOrder.payload.quantity * b.ckexOrder.payload.selections.reduce((p, c) => p * c.odds,1)
        const riskA = (expectedReturnA - parseFloat(a.ckexOrder.payload.quantity))/ currencyRateA
        const riskB = (expectedReturnB - parseFloat(b.ckexOrder.payload.quantity))/ currencyRateB
        return riskA - riskB
      },
      render: (_, {ticket, ckexOrder, id}) => {
        if (!ticket.Sender || !ticket.Bets) return '';
        const currency = ticket.Sender?.Currency;
        const currencyRate = currencyRates[currency];
        const expectedReturn = ckexOrder.payload.quantity * ckexOrder.payload.selections.reduce((p, c) => p * c.odds,1)
        const risk = (expectedReturn - parseFloat(ckexOrder.payload.quantity))/ currencyRate
        return formatCurrency(risk)
      }
    },
    {
      title: 'Amount',
      key: 'amount',
      fixed: 'right',
      width: "4%",
      sorter: (a, b) => {
        const currencyRateA = currencyRates[a.ticket.Sender?.Currency];
        const currencyRateB = currencyRates[b.ticket.Sender?.Currency];
        const amountA = parseFloat(a.ckexOrder.payload.quantity) / currencyRateA
        const amountB = parseFloat(b.ckexOrder.payload.quantity) / currencyRateB
        return amountA - amountB;
      },
        render: (_, {ticket, ckexOrder}) => {
        if (!ticket.Sender || !ticket.Bets) return '';
        const currency = ticket.Sender?.Currency;
        const currencyRate = currencyRates[currency];
        return formatCurrency(parseFloat(ckexOrder.payload.quantity) / currencyRate)
      }
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      fixed: 'right',
      width: "5%",
      ellipsis: true,
      render: (_, {createdAt, id}) => {
        if (!createdAt || typeof createdAt !== "object") return ''
        let dateTime = moment(createdAt?.toDate())
        return <Tooltip title={dateTime.format('YYYY-MM-DD HH:mm:ss')} key={id} placement="bottom">
          {dateTime.format('YYYY-MM-DD HH:mm:ss')}
        </Tooltip>
      },
      sorter: (a, b) => moment(a.createdAt?.toDate()).unix() - moment(b.createdAt?.toDate()).unix()
    },
  ];

  const expandedRowRender = (record) => {
    const epColumn =  [
      {
        title: 'League',
        key: 'season_name',
        render: (_, selection) => eventsDetail[selection.event_id].season_name
      },
      {
        title: 'Country',
        key: 'country',
        render: (_, selection) => eventsDetail[selection.event_id].country
      },
      {
        title: 'PreMatch / InPlay',
        width: 120,
        key: 'status',
        render: (_, selection) => <Tag color={eventsDetail[selection.event_id].status === 'started' ? 'green' : 'yellow'} key={selection.id}>
          {eventsDetail[selection.event_id].status.toUpperCase()}
        </Tag>
      },
      {
        title: 'Event Type',
        key: 'event_type',
        render: (_, selection) => eventsDetail[selection.event_id].event_type
      },
      {
        title: 'Team A',
        key: 'team_a',
        render: (_, selection) => eventsDetail[selection.event_id].team_a.name
      },
      {
        title: 'Team B',
        key: 'team_b',
        render: (_, selection) => eventsDetail[selection.event_id].team_b.name
      },
      {
        title: 'Start Date',
        dataIndex: 'start_date',
        key: 'start_date',
        ellipsis: true,
        render: (_, selection) => {
          const dateTime = moment(eventsDetail[selection.event_id].start_date)
          return <Tooltip title={dateTime.format('YYYY-MM-DD HH:mm:ss')} key={record.id} placement="bottom">
            {dateTime.format('YYYY-MM-DD HH:mm:ss')}
          </Tooltip>
        },
      },
      {
        title: 'Event',
        key: 'event',
        render: (_, selection) => {
          return eventsDetail[selection.event_id].event_name
        }
      },
      {
        title: 'Market',
        key: 'market',
        render: (_, selection) => eventsDetail[selection.event_id].markets[selection.market_id].market_name
      },
      {
        title: 'Product',
        key: 'product',
        render: (_, selection) => eventsDetail[selection.event_id].markets[selection.market_id].chloe_products[selection.product_id].product_name
      },
      {
        title: 'Currency',
        key: 'currency',
        width: 95,
        render: (_, selection) => record.ticket.Sender?.Currency
      },
      {
        title: 'Odds',
        key: 'odds',
        width: 70,
        render: (_, selection) => selection.odds
      },
    ];

    return <Table
        className={"expandTable"}
        columns={epColumn}
        dataSource={record.ckexOrder.payload.selections.slice(1)}
        // showHeader={false}
        rowKey="event_id"
        scroll={{ x: 1000}}
        pagination={false}
    />;
  };

  return (
    <Card>
      <FetchDataBar {...barConfig}/>
      {
        data && <Table
              dataSource={data}
              columns={columns.filter(item => !item.visible)}
              loading={loading}
              rowKey="key"
              size={"small"}
              onChange={
                (pagination, filters, sorter, extra) => {
                  setCount(extra.currentDataSource.length)
                }
              }
              pagination={{defaultPageSize: 100}}
              expandable={{
                rowExpandable: (record) => record.ckexOrder?.payload.selections?.length > 1,
                expandRowByClick: true,
                expandIconAsCell: true,
                expandedRowRender: record => expandedRowRender(record),
                defaultExpandedRowKeys: ['0'],
              }}
              scroll={{ x: 1800, y: 650 }}
          />
      }
    </Card>
  );
}

export default OrderList;
