/* eslint-disable no-unused-expressions,no-shadow,max-len */
import React, { useState, useEffect } from 'react';
import { round, each } from 'lodash';
import { measureTextWidth, Pie } from '@ant-design/charts';

function renderStatistic(containerWidth, text, style) {
  const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
  const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

  let scale = 1;

  if (containerWidth < textWidth) {
    scale = Math.min(Math.sqrt(Math.abs(R ** 2 / ((textWidth / 2) ** 2 + textHeight ** 2))), 1);
  }

  const textStyleStr = `width:${containerWidth}px;`;
  return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
}

function EventPie({ data, currencyRates, getMtsRate }) {
  const [pieData, setPieData] = useState([]);
  useEffect(() => {
    const pieMap = {};
    let pData = [];
    data.forEach((item) => {
      if (item.status !== 'completed') return;
      const currency = item.originTicket?.Sender.Currency;
      item.ckexOrder.payload.forEach((bet) => {
        const amount = round(bet.quantity / currencyRates[currency], 2);
        pieMap[bet.selections[0].event_id] ? pieMap[bet.selections[0].event_id] += amount : pieMap[bet.selections[0].event_id] = amount;
      });
    });
    each(pieMap, ((item, key) => {
      pData.push({
        type: key,
        value: round(item, 2),
      });
    }));
    pData = pData.sort((a, b) => b.value - a.value).slice(0, 15);
    setPieData(pData);
  }, [data]);

  const config = {
    appendPadding: 0,
    data: pieData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `${v} €`,
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: '{value}',
    },
    statistic: {
      title: {
        offsetY: -2,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt((width / 2) ** 2 + (height / 2) ** 2);
          const text = datum ? datum.type : 'Amount';
          return renderStatistic(d, text, {
            fontSize: 40,
          });
        },
      },
      content: {
        offsetY: 5,
        style: {
          fontSize: '32px',
        },
        // eslint-disable-next-line no-shadow
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect();
          const text = datum ? `€ ${datum.value}` : `€ ${round(data.reduce((r, d) => r + d.value, 0), 2)}`;
          return renderStatistic(width, text, {
            fontSize: 32,
          });
        },
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  };
  return (
    <div style={{ fontSize: 20, height: '100%' }}>
      <div style={{ fontSize: 20 }}>Top 15 Events Distribution</div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Pie {...config} style={{ marginTop: 15 }} />
    </div>
  );
}

export default EventPie;
