/* eslint-disable */
import React, { useEffect } from 'react';
import {
  Card,
  Modal,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import useOrderList from "./useOrderList";
import {EyeOutlined, PlayCircleFilled, SearchOutlined, SyncOutlined} from "@ant-design/icons";
import ReactJson from 'react-json-view'
import moment from "moment/moment";
import useOMS from "../../../hooks/useOMS";
import useCurrency from "../../../hooks/useCurrency";
import FetchDataBar from "../../../components/FetchDataBar/FetchDataBar";

function OrderList() {
  const {
    currencyRates,
    formatCurrency,
    getMtsRate,
  } = useCurrency()
  const {
    modalData,
    setModalData,
    isModalOpen,
    setIsModalOpen,
    getIPSearchProps,
    getIDSearchProps,
    getEventIDSearchProps,
    getUsernameSearchProps,
    getTicketIDSearchProps,
    getMerchantCodeSearchProps,
  } = useOrderList()
  const {
    data,
    setEnv,
    loading,
    setStartAt,
    setEndAt,
    startAt,
    endAt,
    count,
    setCount,
    isLive,
    setIsLive,
    cancelledCodeFilter,
    fetch,
  } = useOMS()

  useEffect(() => {
    fetch({status: 'all'});
  }, []);

  let barConfig = {
    setEnv,
    setStartAt,
    setEndAt,
    startAt,
    endAt,
    count,
    isLive,
    setIsLive,
    fetch: () => fetch({status: 'all'}),
  }
  const showModal = (data) => {
    setModalData(data)
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'Id',
      width: 100,
      ellipsis: true,
      ...getIDSearchProps(),
      render: (_, record) => (
          <Tooltip title={record.id} key={record.id} placement="bottom">
            {record.id}
          </Tooltip>),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      filterMode: 'menu',
      filterSearch: true,
      filters: [
        {
          text: 'pending',
          value: 'pending',
        },
        {
          text: 'processing',
          value: 'processing',
        },
        {
          text: 'completed',
          value: 'completed',
        },
        {
          text: 'cancelled',
          value: 'cancelled',
        },
        {
          text: 'failed',
          value: 'failed',
        }
        ],
      onFilter: (value, record) => record.status === value,
      render: (_, {status, id}) => {
        if (!status) {
          return ''
        }
        let color = status === 'cancelled' ? 'red' :
                    status === 'completed' ? 'green' :
                    'yellow'
        return <Tag color={color} key={id}>
          {status}
        </Tag>
      }
    },
    {
      title: 'Merchant Code',
      key: 'merchantCode',
      ellipsis: true,
      ...getMerchantCodeSearchProps(),
      render: (_, record) => (
          <Tooltip title={record.merchantCode} key={record.merchantCode} placement="bottom">
            {record.merchantCode}
          </Tooltip>),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      textWrap: 'word-break',
      ellipsis: true,
      ...getUsernameSearchProps(),
      render: (_, record) => (
          <Tooltip title={record.username} key={record.id} placement="bottom">
            <a >{record.username}</a>
          </Tooltip>),
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
      ellipsis: true,
      ...getIPSearchProps(),
    },
    {
      title: 'Events',
      key: 'event',
      ...getEventIDSearchProps(),
      render: (_, {originTicket, id}) => <div>
        {
          originTicket.Selections?.map((bet, idx) => {
            const colorList = ['magenta', 'purple', 'orange', 'cyan', 'lime']
            let eventInfo = bet.ID.split('/');
            let marketId = eventInfo[1]
            let produceId = eventInfo[2]
            let timDom = () => (<div>
              Market Id : {marketId} <br/>
              Product Id : {produceId} <br/>
              Odds : {bet.Odds / 10000}
            </div>)
            return <Tag color={colorList[idx%colorList.length]} key={`${id}_${idx}`} style={{marginTop: 2, cursor: "pointer"}}>
              <Tooltip title={timDom} key={id} placement="bottom">
                {bet.EventId}
              </Tooltip></Tag>
          })
        }
      </div>
    },
    {
      title: 'Amount',
      key: 'amount',
      render: (_, {ticket}) => {
        if (!ticket.Sender || !ticket.Bets) return '';
        const currency = ticket.Sender?.Currency;
        const currencyRate = currencyRates[currency];
        const amount = ticket.Bets.reduce((pre, curr) => pre + curr.Stake.Value, 0) / currencyRate / getMtsRate(currency)
        return <div>{formatCurrency(amount)}</div>
      }
    },
    {
      title: 'Cancelled Code',
      dataIndex: 'cancelledCode',
      key: 'cancelledCode',
      width: 110,
      filterMode: 'menu',
      filterSearch: true,
      filters: cancelledCodeFilter.map(item => ({
        text: item ? item : '',
        value: item ? item : '',
      })),
      onFilter: (value, {cancelledCode}) => {
        if (!cancelledCode) cancelledCode = ''
        return cancelledCode === value
      },
      render: (_, {cancelledCode, ckexOrder, details, id}) => {
        if (!cancelledCode) {
          return ''
        }
        if (cancelledCode !== 5000 && cancelledCode !== 8000) {
          return <Tag color="red" key={id}>{cancelledCode}</Tag>
        }
        const sportRardatError = () => (<div>
          {
              Array.isArray(details) && details.map((detail, index) => (<div>
                <div>Code: {detail.Reason?.Code}</div>
                <hr/>
                <div>
                  Reason: {detail.Reason?.Message}
                </div>
                {index !== details.length -1 ?? <br/>}
              </div>))
          }
        </div>)
        const ckexError = () => (<div>
          <div>Code: {ckexOrder.response?.code}</div>
          <hr/>
          <div>
            Reason: {ckexOrder.response?.error}
          </div>
        </div>)
        let tipDom = cancelledCode === 5000 ? sportRardatError : ckexError
        return <Tag color="red" key={id} style={{cursor: "pointer"}}>
              <Tooltip title={tipDom} key={id} placement="bottom">
                {cancelledCode}
              </Tooltip>
            </Tag>
      }
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      width: 90,
      ...getTicketIDSearchProps(),
      render: (_, {details, ckexOrder, ticket, originTicket, id}) => <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}><EyeOutlined
          onClick={()=>showModal({ckexOrder, ticket, originTicket, details})}
          style={{
            cursor: "pointer",
            fontSize: 20}
          }
          key={id} /></div>
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ellipsis: true,
      render: (_, {updatedAt, id}) => {
        if (!updatedAt || typeof updatedAt !== "object") return ''
        let dateTime = moment(updatedAt?.toDate())
        const duration = moment.duration(dateTime.diff(moment()))
        return <Tooltip title={dateTime.format('YYYY-MM-DD HH:mm:ss')} key={id} placement="bottom">
          {dateTime.format('YYYY-MM-DD HH:mm:ss')}
        </Tooltip>
      },
      sorter: (a, b) => moment(a.createdAt?.toDate()).unix() - moment(b.createdAt?.toDate()).unix()
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      render: (_, {createdAt, id}) => {
        if (!createdAt || typeof createdAt !== "object") return ''
        let dateTime = moment(createdAt?.toDate())
        return <Tooltip title={dateTime.format('YYYY-MM-DD HH:mm:ss')} key={id} placement="bottom">
          {dateTime.format('YYYY-MM-DD HH:mm:ss')}
        </Tooltip>
      },
      sorter: (a, b) => moment(a.createdAt?.toDate()).unix() - moment(b.createdAt?.toDate()).unix()
    },
  ];

  return (
    <Card>
      <FetchDataBar {...barConfig}/>
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        rowKey="id"
        onChange={
          (pagination, filters, sorter, extra) => {
            setCount(extra.currentDataSource.length)
          }
        }
        pagination={{
          defaultPageSize: 15,
        }}
        scroll={{ y: 650 }}
      />
      <Modal title="Detail" open={isModalOpen} onOk={()=> setIsModalOpen(false)} onCancel={()=> setIsModalOpen(false)}>
        <ReactJson src={modalData} collapsed={1} collapseStringsAfterLength={20} displayDataTypes={false}/>
      </Modal>
    </Card>
  );
}

export default OrderList;
