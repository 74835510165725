/* eslint-disable operator-linebreak */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from '../../../ routes';
import { MTXX } from '../../../utils/constants';

function MtxxRoutes() {
  return (
    <Routes>
      {routes.map((route) => (
        <Route
          key={route.identity.includes(MTXX) && route.path}
          path={route.identity.includes(MTXX) && route.path}
          element={route.identity.includes(MTXX) && route?.element}
        >
          {route.identity.includes(MTXX) &&
            route.children &&
            route.children.map((child) => (
              <Route
                key={child.path}
                path={child.path}
                element={child.element}
              />
            ))}
        </Route>
      ))}
    </Routes>
  );
}

export default MtxxRoutes;
