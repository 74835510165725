import styled from 'styled-components';

const StyledDashboard = styled.div`
.site-layout-content {
  min-height: 70vh;
  padding: 24px;
  background: #fff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.error {
  color: red
}
`;

export default StyledDashboard;
