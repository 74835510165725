/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { bytesToBand } from '../../../utils/helper';
import axios from "axios";

function getOption(name) {
  return {
    title: {
      text: name,
    },
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      left: '2%',
      right: '4%',
      bottom: '2%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: [],
      },
    ],
    yAxis: [
      {
        type: 'value',
        max: 100,
      },
    ],
    series: [
      {
        name,
        type: 'line',
        areaStyle: { normal: {} },
        data: [],
      },
    ],
  };
}

function getConnOption() {
  return {
    title: {
      text: 'Connections',
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['Rtmp', 'Http', 'WebSocket'],
    },
    grid: {
      left: '2%',
      right: '4%',
      bottom: '2%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: [],
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [
      {
        name: 'Rtmp',
        type: 'line',
        data: [],
      },
      {
        name: 'Http',
        type: 'line',
        data: [],
      },
      {
        name: 'WebSocket',
        type: 'line',
        data: [],
      },
    ],
  };
}

function getNetOption() {
  return {
    title: {
      text: 'Network Bandwidth',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        animation: false
      }
    },
    axisPointer: {
      link: { xAxisIndex: 'all' }
    },
    legend: {
      data: ['Input', 'Output']
    },
    grid: [{
      left: 50,
      right: 50,
      height: '35%'
    }, {
      left: 50,
      right: 50,
      top: '55%',
      height: '35%'
    }],
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        axisLine: { onZero: true },
        data: [],
        show: false,
      },
      {
        gridIndex: 1,
        type: 'category',
        boundaryGap: false,
        axisLine: { onZero: true },
        data: [],
        position: 'bottom'
      }
    ],
    yAxis: [
      {
        name: 'Mbps',
        type: 'value',
      },
      {
        gridIndex: 1,
        type: 'value',
        inverse: true
      }
    ],
    series: [
      {
        name: 'Input',
        type: 'line',
        data: []
      },
      {
        name: 'Output',
        type: 'line',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: []
      }
    ]
  };
}

function useDashBoard() {
  const count = useRef(0);
  const lastInBytes = useRef(0);
  const lastOtBytes = useRef(0);
  const [cpuOption, setCpuOption] = useState(getOption('CPU Usage'));
  const [memOption, setMemOption] = useState(getOption('Memory Usage'));
  const [conOption, setConOption] = useState(getConnOption());
  const [netOption, setNetOption] = useState(getNetOption());
  const timer = useRef(null);

  const fetch = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_RELAY_SERVER_URL_PROD}api/server`);
    lastInBytes.current = lastInBytes.current || data.net.inbytes;
    lastOtBytes.current = lastOtBytes.current || data.net.outbytes;

    const now = new Date();
    const axisData = now.toLocaleTimeString().replace(/^\D*/, '');
    const cpuOp = { ...cpuOption };
    const memOp = { ...memOption };
    const conOp = { ...conOption };
    const netOp = { ...netOption };

    if (count.current++ > 30) {
      cpuOp.xAxis[0].data.shift();
      cpuOp.series[0].data.shift();

      memOp.xAxis[0].data.shift();
      memOp.series[0].data.shift();

      conOp.xAxis[0].data.shift();
      conOp.series[0].data.shift();
      conOp.series[1].data.shift();
      conOp.series[2].data.shift();

      netOp.xAxis[0].data.shift();
      netOp.xAxis[1].data.shift();
      netOp.series[0].data.shift();
      netOp.series[1].data.shift();
    }

    cpuOp.uptime = now;
    cpuOp.xAxis[0].data.push(axisData);
    cpuOp.series[0].data.push(data.cpu.load);

    memOp.uptime = now;
    memOp.xAxis[0].data.push(axisData);
    memOp.series[0].data.push((100 - 100 * data.mem.free / data.mem.totle).toFixed(2));

    conOp.uptime = now;
    conOp.title.text = `Connections ${data.clients.rtmp + data.clients.http + data.clients.ws}`;
    conOp.xAxis[0].data.push(axisData);
    conOp.series[0].data.push(data.clients.rtmp);
    conOp.series[1].data.push(data.clients.http);
    conOp.series[2].data.push(data.clients.ws);

    netOp.uptime = now;
    netOp.xAxis[0].data.push(axisData);
    netOp.xAxis[1].data.push(axisData);
    netOp.series[0].data.push(bytesToBand((data.net.inbytes - lastInBytes.current) / 2));
    netOp.series[1].data.push(bytesToBand((data.net.outbytes - lastOtBytes.current) / 2));
    lastInBytes.current = data.net.inbytes;
    lastOtBytes.current = data.net.outbytes;
    setConOption(conOp);
    setCpuOption(cpuOp);
    setMemOption(memOp);
    setNetOption(netOp);
  };

  useEffect(() => {
    fetch();
    timer.current = setInterval(() => fetch(), 2000);
    return () => clearInterval(timer.current);
  }, []);

  return {
      conOption,
      cpuOption,
      memOption,
      netOption
  }
}

export default useDashBoard;
