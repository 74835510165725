import React from 'react';
import useLogout from './useLogout';

function LogoutPage() {
  const { logout } = useLogout();
  logout();
  return <div>LogoutPage</div>;
}

export default LogoutPage;
