/* eslint-disable comma-dangle */
import React from 'react';

import Login from '../../components/Login/Login';

function LoginPage() {
  return (
    <div
      className="account-and-password-block"
      style={{
        display: 'flex',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%'
      }}
    >
      <Login />
    </div>
  );
}

export default LoginPage;
