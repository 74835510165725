/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react';
import {Button, Card, Checkbox, Col, Form, Input, message, Row, Select, Space, Table, Tag} from 'antd';
import useMapping from './useMapping';
import moment from "moment";
import {updateMatchId} from "../../../Api/streamAPI";
import {Option} from "antd/es/mentions";

const days = [0,1,2,3,4,5,6,7]

function Mapping() {
  const {
    sportTypeFilter,
    eventIdFilter,
    teamAFilter,
    teamBFilter,
    loading,
    setLoading,
    data,
    setData,
    env,
    setEnv,
    dayBefore,
    setDayBefore,
    dayAfter,
    setDayAfter,
    fetch,
  } = useMapping();

  const handleUpdate = async (record) => {
    try {
      setLoading(true)
      await updateMatchId(env, record.event_id, 'radar_stream',record.radar_stream_match_id)
      await updateMatchId(env, record.event_id, 'img_stream',record.img_stream_match_id)
      toggleEdit(record)
      setLoading(false)
      message.success('Update Match Id Successfully');
    } catch (e) {
      setLoading(false)
      message.error('Update Match Id error: ', e);
    }
  }
  const toggleEdit = (record) => {
      setData((prev) => prev.map(item => {
        if (item.event_id === record.event_id) {
          item.isEdited = !item.isEdited
        }
        return item
      }))
  }

  const columns = [
    {
      title: 'Event ID',
      dataIndex: 'event_id',
      key: 'event_id',
      filterMode: 'menu',
      filterSearch: true,
      filters: eventIdFilter,
      onFilter: (value, record) => record.event_id === value,
    },
    // {
    //   title: 'Event Type ID',
    //   dataIndex: 'event_type_id',
    //   sorter: (a, b) => a.event_type_id - b.event_type_id,
    //   key: 'event_type_id',
    // },
    {
      title: 'Sport',
      dataIndex: 'sport',
      key: 'sport',
      filterMode: 'menu',
      filterSearch: true,
      filters: sportTypeFilter,
      onFilter: (value, record) => record.sport === value,
    },
    {
      title: 'TeamA',
      dataIndex: 'team_a_name',
      key: 'team_a_name',
      filterMode: 'menu',
      filterSearch: true,
      filters: teamAFilter,
      onFilter: (value, record) => record.team_a_name === value,
    },
    {
      title: 'TeamB',
      dataIndex: 'team_b_name',
      key: 'team_b_name',
      filterMode: 'menu',
      filterSearch: true,
      filters: teamBFilter,
      onFilter: (value, record) => record.team_b_name === value,
    },
    {
      title: 'Start Time',
      dataIndex: 'start_dt',
      key: 'start_dt',
      sorter: (a, b) => moment(a.start_dt).unix() - moment(b.start_dt).unix()
    },
    {
      title: 'Match Id',
      dataIndex: 'radar_stream_match_id',
      key: 'radar_stream_match_id',
      render: (_, record) => {
        return (
            <div>
              {
                !record?.radar_stream_match_id ?
                    <span style={{display: record.isEdited ? "none" : "block"}}></span> :
                    <Tag color="green" style={{display: record.isEdited ? "none" : "block"}}>
                      {record.radar_stream_match_id}
                    </Tag>
              }
              <Input
                  placeholder="match id"
                  style={{display: !record.isEdited ? "none" : "block"}}
                  defaultValue={record.radar_stream_match_id}
                  onChange={(e) => {
                    setData((prev) => prev.map(item => {
                      if (item.event_id === record.event_id) {
                        item.radar_stream_match_id = (e.target.value).trim()
                      }
                      return item
                    }))
                  }}
              />
            </div>
        )
      }
    },
    {
        title: 'IMG Match Id',
        dataIndex: 'img_stream_match_id',
        key: 'img_stream_match_id',
        render: (_, record) => {
            return (
                <div>
                    {
                        !record?.img_stream_match_id ?
                            <span style={{display: record.isEdited ? "none" : "block"}}></span> :
                            <Tag color="green" style={{display: record.isEdited ? "none" : "block"}}>
                                {record.img_stream_match_id}
                            </Tag>
                    }
                    <Input
                        placeholder="img match id"
                        style={{display: !record.isEdited ? "none" : "block"}}
                        defaultValue={record.img_stream_match_id}
                        onChange={(e) => {
                            setData((prev) => prev.map(item => {
                                if (item.event_id === record.event_id) {
                                    item.img_stream_match_id = (e.target.value).trim()
                                }
                                return item
                            }))
                        }}
                    />
                </div>
            )
        }
    },
    {
      title: 'Action',
      key: 'action',
      render: (i, record) => (
          <Space size="middle">
            {
              !record.isEdited ?
                  (<a onClick={()=> toggleEdit(record)}>Edit</a>) :
                  (<a onClick={()=> handleUpdate(record)}>Update</a>)
            }
          </Space>
      )
    },
  ];

  return (
    <Card>
      <div style={{
        marginBottom: 20,
        marginTop: 10,
      }}>
        <label
            style={{
              marginLeft: 10,
            }}
        >Environment:</label>
        <Select
            defaultValue="dev"
            style={{
              width: 100,
              marginLeft: 5,
            }}
            onChange={(value) => setEnv(value)}
        >
          <Select.Option key="dev" value="dev" >Develop</Select.Option>
          <Select.Option key="prod" value="prod">Production</Select.Option>
        </Select>
        <label
            style={{
              marginLeft: 30,
            }}
        >Day Before:</label>
        <Select
            defaultValue="1"
            style={{
              width: 100,
              marginLeft: 5,
            }}
            onChange={(value) => {
              setDayBefore(value)
            }}
        >
          {
            days.map(day => <Select.Option key={day} value={day}>{day}</Select.Option>)
          }
        </Select>
        <label
          style={{
            marginLeft: 30,
          }}
        >Day After:</label>
        <Select
            defaultValue="1"
            style={{
              width: 100,
              marginLeft: 5,
            }}
            onChange={(value) => setDayAfter(value)}
        >
          {
            days.map(day => <Select.Option key={day} value={day}>{day}</Select.Option>)
          }
        </Select>
        <Button
            type="primary"
            style={{
              width: 120,
              marginLeft: 30,
            }}
            onClick={() => {
              fetch(env, dayBefore, dayAfter)
            }}
        >
          Submit
        </Button>
      </div>
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={{
          defaultPageSize: 10
        }}
      />
    </Card>
  );
}

export default Mapping;
