/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import routes from './ routes';
import AdminRoutes from './components/Routes/AdminRoutes/AdminRoutes';
import MtboRoutes from './components/Routes/MtboRoutes/MtboRoutes';
import { selectUserInfo } from './features/userSlice';
import useOath from './hooks/useAuth';
import Layout from './layouts/Layout';
import { ADMIN, MTBO, MTXX } from './utils/constants';
// eslint-disable-next-line import/no-unresolved,import/no-extraneous-dependencies
import '@ant-design/flowchart/dist/index.css';
import MtxxRoutes from './components/Routes/MtxxRoutes/MtxxRoutes';

function App() {
  const userInfo = useSelector(selectUserInfo);
  const { user } = userInfo;
  useOath();

  return (
    <Router>
      <Layout routes={routes}>
        {user === ADMIN && <AdminRoutes />}
        {user === MTBO && <MtboRoutes />}
        {user === MTXX && <MtxxRoutes />}
        {user !== ADMIN && user !== MTBO && user !== MTXX && <MtxxRoutes />}
      </Layout>
    </Router>
  );
}

export default App;
