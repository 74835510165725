/* eslint-disable */
import { Breadcrumb } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';

function BreadCrumb() {
  const location = useLocation();
  const paths = location.pathname.split('/');
  return (
    <Breadcrumb
      style={{
        margin: '16px 0'
      }}
    >
      <Breadcrumb.Item>Home</Breadcrumb.Item>
      {
         paths.map((path) => {
           if (!path) return;
           return (
             <Breadcrumb.Item key={path}>{path[0].toUpperCase() + path.substring(1)}</Breadcrumb.Item>
           );
         })
      }
    </Breadcrumb>
  );
}

export default BreadCrumb;
