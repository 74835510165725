/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
import { doc, getDoc } from 'firebase/firestore';
import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import useIdentity from '../../../hooks/useIdentity';
import { authMtbo } from '../../../utils/firebase-config-mtbo';
import { setUserInfo } from '../../../features/userSlice';

const useEmailAndPasswordLogIn = (loginEmail, loginPassword) => {
  const { getIdentity } = useIdentity();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 輸入帳密登入
  const signInWithEmailAccountAndPassword = async () => {
    try {
      const user = await signInWithEmailAndPassword(
        authMtbo,
        loginEmail,
        loginPassword
      );
      const token = user?.user?.accessToken;
      const payload = {
        user: getIdentity(token),
        accessToken: token,
        apiKey: authMtbo?.config?.apiKey,
        aud: jwt_decode(token).aud
      };
      dispatch(setUserInfo(payload));
      // 登入成功就轉址
      navigate('/member/change-password');
      message.success('login successfully');
      sessionStorage.setItem('accessToken', token);
    } catch (error) {
      message.error('login failed: ', error);
    }
  };

  return {
    signInWithEmailAccountAndPassword
  };
};

export default useEmailAndPasswordLogIn;
