// 身份常數
export const ADMIN = 'ADMIN'; // 管理者身份
export const MTBO = 'MTBO'; // 商戶身份
export const MTXX = 'MTXX'; // 總代身份

// 加密的 hostname 常數
export const ENCODED_BACKOFFICE_D9E32_AECRICEX_ONE = 'YmFja29mZmljZS1kOWUzMi5hZWNyaWNleC5vbmU='; // backoffice localhost
export const ENCODED_LOCALHOST = 'bG9jYWxob3N0'; // 本地開發 localhost

// token 中的 aud 常數 (解碼 token 後，其中的 aud 會包含登入者的身份)
export const AUD_FIRST_PARAM_AD = 'ad'; // 管理者 aud 的第一個參數
export const AUD_FIRST_PARAM_MT = 'mt'; // 商戶 aud 的第一個參數
export const AUD_FIRST_PARAM_SMT = 'smt'; // 商戶 aud 的第一個參數
export const AUD_THIRD_PARAM_MTXX = 'main'; // 總代 aud 的第三個參數

// 使用者登入的常數
export const SIGNIN_PROVIDER_PASSWORD = 'password'; // 限定管理者以外使用帳密登入的參數
export const GOOGLE_AUTH_HD_PARAM = 'ckex.tech'; // 限定管理者使用公司信箱登入的參數
