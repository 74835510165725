/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react';
import {Card, Dropdown, Menu, Space, Table, Tooltip} from 'antd';
import useRadar from './useRadar';
import moment from "moment";


const matchStatusInfo = `
調整MatchStatus 比賽狀態碼
  - 足球比賽 1 未開賽 2 上半場 3 中場異常 4 下半場 7 點球比賽 8 9 推遲 10 場結束 11 腰斬
  - 籃球 0異常 1未開賽 2第一節 3第一節結束 4第二節 5第二節結束 6第三7第三節結束 8第四節 9加時 10完場 11結束 12取消 13 14腰斬
  - 網球：0：異常 1未開始 51盤 52第二盤 53第三盤 54比賽盤 55第五盤 1000完場 20直接第四盤 21退出 221不戰勝 23選手2不戰 24名選手 11退賽 25名選手退出賽 261名選手 27名選手放棄退賽 14名選手延期 1516名選手取消 17名選手退出 19名選手腰斬
  - 電競：0比賽異常 1未開始 2進行中 11中斷 12取消 13取消 14腰斬
`
function Radar() {
  const { data,
    loading,
    sportTypeFilter,
    oppAFilter,
    oppBFilter,
    ligaFilter,
    ligaIdFilter,
    streamNameFilter,
  } = useRadar();
  const columns = [
    {
      title: 'League Id',
      dataIndex: 'LeagueID',
      key: 'LeagueID',
      filterMode: 'menu',
      filterSearch: true,
      filters: ligaIdFilter,
      onFilter: (value, record) => record.LeagueID === value,
    },
    {
      title: 'Liga',
      dataIndex: 'LigaEn',
      key: 'LigaEn',
      filterMode: 'menu',
      filterSearch: true,
      filters: ligaFilter,
      onFilter: (value, record) => record.LigaEn === value,
    },
    {
      title: 'Sport',
      dataIndex: 'SportEn',
      key: 'SportEn',
      filterMode: 'menu',
      filterSearch: true,
      filters: sportTypeFilter,
      onFilter: (value, record) => record.SportEn === value,
    },
    {
      title: 'Opp1En',
      dataIndex: 'Opp1En',
      key: 'Opp1En',
      render: (_, record) => (
          <Tooltip title={record.Opp1ID} key={record.Opp1En} placement="bottom">
            <a >{record.Opp1En}</a>
          </Tooltip>
      ),
      filterMode: 'menu',
      filterSearch: true,
      filters: oppAFilter,
      onFilter: (value, record) => record.Opp1En === value,
    },
    {
      title: 'Opp2En',
      dataIndex: 'Opp2En',
      key: 'Opp2En',
      render: (_, record) => (
          <Tooltip title={record.Opp2ID} key={record.Opp2En} placement="bottom">
            <a >{record.Opp2En}</a>
          </Tooltip>
      ),
      filterMode: 'menu',
      filterSearch: true,
      filters: oppBFilter,
      onFilter: (value, record) => record.Opp2En === value,
    },
    {
      title: 'StreamName',
      dataIndex: 'StreamName',
      key: 'StreamName',
      filterMode: 'menu',
      filterSearch: true,
      filters: streamNameFilter,
      onFilter: (value, record) => record.StreamName === value,
    },
    {
      title: 'Start Time',
      dataIndex: 'Time',
      key: 'Time',
      sorter: (a, b) => moment(a.Time).unix() - moment(b.Time).unix()
    },
    {
      title: (_, record) => (
          <Tooltip title={() => <div>{matchStatusInfo}</div>} key={record} placement="bottom">
            MatchStatus
          </Tooltip>
      ),
      dataIndex: 'MatchStatus',
      key: 'MatchStatus',
    },
  ];

  return (
    <Card>
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={{
          defaultPageSize: 10
        }}
      />
    </Card>
  );
}

export default Radar;
