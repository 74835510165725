import React from 'react';
import { Button, Col, Input } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

function InputBlock(props) {
  const {
    inputStatus,
    inputIP,
    addWhiteIP,
    setInputIP,
    errorText,
  } = props;

  return (
    <>
      <Col
        span={24}
        style={{
          display: 'flex',
          padding: '0 20px',
          marginBottom: '20px',
          justifyContent: 'center',
          maxWidth: '400px',
        }}
      >
        <Input
          data-testid="input"
          placeholder="Adding White IP..."
          style={{
            display: 'inline-block',
          }}
          status={inputStatus}
          value={inputIP}
          onPressEnter={addWhiteIP}
          onChange={(e) => setInputIP(e.target.value)}
        />
        <Button
          type="primary"
          shape="circle"
          icon={<PlusCircleOutlined />}
          onClick={addWhiteIP}
          style={{
            marginLeft: '5px',
          }}
        />
      </Col>
      {
            inputStatus !== '' && (
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <p className="error">{errorText}</p>
            </Col>
            )
        }
    </>
  );
}

export default InputBlock;
